import { useEffect } from 'react';
import { useNavigate, useOutletContext } from 'react-router-dom';
import InputField from '../../../components/base/InputField';
import { NextStepSectionSBM01 } from '../base/NextStepSectionSBM01';


const CategoryStrengthWeakness = ({ title, items }) => {
	return (
		<>
			<section className="text-[22px] leading-7 font-bold text-zinc-800">
				{title}
			</section>
			{items.map((item, index) => (
				<div key={index} className="flex md:gap-[114px] justify-between mt-4 max-md:mt-[10px] w-full md:max-w-[436px]">
					<div className="flex gap-3 lg:gap-4 self-end mb-8">
						<div className="shrink-0 my-auto w-2 h-2 bg-green-500" />
						<div className="flex-auto flex-shrink-0 whitespace-nowrap lg:text-xl text-lg font-semibold text-zinc-800">{item.category}:</div>
					</div>
					<div className="flex flex-col justify-center text-base text-neutral-500 max-md:max-w-[180px]">
						<InputField
							onChange={() => { }}
							labelClassName={"text-base font-semibold text-neutral-500 -mb-2"}
							label={item.description}
							inputBg="bg-white !rounded-[0px] border border-solid border-zinc-300"
							type="text"
							name="lastName"
							id="lastName"
							value={""}

						/>
					</div>
				</div>
			))}
		</>
	);
};

const Recommendation = ({ recommendations }) => {
	return recommendations.map((recommendation, index) => (
		<section key={index} className="self-stretch mt-[10px] lg:mt-7 w-full max-w-[770px]">
			<InputField
				onChange={() => { }}
				labelClassName={"text-lg font-semibold text-zinc-800 -mb-2"}
				label={recommendation.title}
				inputBg="bg-white !rounded-[0px] border border-solid border-zinc-300"
				type="text"
				name="lastName"
				id="lastName"
				value={""}

			/>
		</section>
	));
};

const PerformanceReview = () => {

	const strengths = [
		{ category: 'Category 1', description: 'Positive Variance %' },
		{ category: 'Category 2', description: 'Positive Variance %' },
		{ category: 'Category 3', description: 'Positive Variance %' }
	];

	const weaknesses = [
		{ category: 'Category 1', description: 'Positive Variance %' },
		{ category: 'Category 2', description: 'Positive Variance %' },
		{ category: 'Category 3', description: 'Positive Variance %' }
	];

	const trainingRecommendations = [
		{ title: 'Recommendation 1' },
		{ title: 'Recommendation 2' },
		{ title: 'Recommendation 3' }
	];

	return (
		<main className="flex flex-col items-start font-semibold">
			<CategoryStrengthWeakness title="Top 3 Strengths:" items={strengths} />
			<hr className="self-stretch my-11 lg:my-14 w-full border border-solid bg-neutral-200 border-neutral-200 min-h-[1px]" />
			<CategoryStrengthWeakness title="Top 3 Weaknesses:" items={weaknesses} />
			<hr className="self-stretch my-11 lg:my-14 w-full border border-solid bg-neutral-200 border-neutral-200 min-h-[1px]" />
			<section className="self-stretch w-full text-[22px] leading-7 font-bold text-zinc-800 max-md:mb-[14px]">
				Training Recommendations:
			</section>
			<Recommendation recommendations={trainingRecommendations} />
		</main>
	);
};




const TrainingInsights = () => {
	const navigate = useNavigate()
	const { job, application, jobId, applicationId, setActiveStep } = useOutletContext()

	useEffect(() => {
		setActiveStep(9)
	}, [])

	const handlePrev = () => {
		navigate(`/SBM01_10_5_5/${jobId}/${applicationId}`)
	}
	const handleNext = () => {
		navigate(`/SBM01_10_7/${jobId}/${applicationId}`)
	}


	return (
		<div className='flex-auto'>
			<div className='flex flex-col gap-11 max-w-[1440px] px-5 w-full pt-10 pb-20 lg:pt-14 lg:pb-24 mx-auto'>
				<PerformanceReview />
			</div>
			<NextStepSectionSBM01 prev={handlePrev} next={handleNext} />
		</div>
	)
}

export default TrainingInsights