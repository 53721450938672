import React, { useState } from "react";
import ContactUsLinkBlock from "../../components/parts/ContactUsLinkBlock";


const Step6 = ({ setContactFormOpen, qaPresetAttributesSelected, bottomPaste = null }) => {
    const [modalStyle, setModalStyle] = useState({ opacity: 0 });
    const [triangleStyle, setTriangleStyle] = useState({});
    const [modalOverTop, setModalOverTop] = useState(false);

    const itemHover = (e) => {
        if(e.target){
            const mouseOverTargetClientRect = e.target.getBoundingClientRect();

            if(e.target.querySelector(".description-tip-modal-sbm-desktop")){
                const modalClientRect = e.target.querySelector(".description-tip-modal-sbm-desktop").getBoundingClientRect();

                setModalStyle({
                    left: ((mouseOverTargetClientRect.left-40) + modalClientRect.width) > document.body.offsetWidth ? ((document.body.offsetWidth - modalClientRect.width) - 20) : (mouseOverTargetClientRect.left-40),
                    bottom: (window.innerHeight - mouseOverTargetClientRect.bottom) + 68,
                    opacity: 1
                });

                setTriangleStyle({
                    left: mouseOverTargetClientRect.left,
                    top: mouseOverTargetClientRect.top,
                });

                setTimeout(() => {
                    const newModalClientRect = e.target.querySelector(".description-tip-modal-sbm-desktop").getBoundingClientRect();
                    if(newModalClientRect?.top < 0){
                        setModalOverTop(true);
                    } else {
                        setModalOverTop(false);
                    }
                }, 50);
            }
        }
    }

    return (
        <>
            <div className='text-center flex gap-3 items-center justify-center flex-col'>
                <span className='text-[40px] font-bold max-md:text-[36px]'>Interview Questions</span>
                <span className='text-[18px] leading-[30px] max-md:text-[16px] max-md:leading-[22px]'>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque ornare scelerisque erat vel rutrum. Duis sem enim, tempor sed gravida imperdiet, <br className="max-md:hidden" />
                    bibendum et purus. Donec egestas lectus at sapien hendrerit non convallis magna scelerisque. Mauris hendrerit erat sit amet enim pharetra ut <br className="max-md:hidden" />
                    consectetur odio pharetra. Nam ut mauris et purus fermentum suscipit vel eget mi.
                </span>
            </div>

            <div className="lg:border border-neutral-200 px-[60px] py-[50px] max-md:p-[0px]">
                { qaPresetAttributesSelected ? <div className="flex flex-col gap-6">
                    <label htmlFor="check" className="checkbox-green-container bigger">
                        <input type="checkbox" hidden id="check" checked />
                        <div className="checkmark-green scale-150"></div>
                        <span className="checkmark-label-optional font-semibold text-[26px] flex-1 max-md:text-[18px]">Interview Questions for the { qaPresetAttributesSelected.title }</span>
                    </label>

                    {/* max-lg:overflow-x-auto max-lg:overflow-y-hidden */}
                    <div className="lg:pl-12 gap-3 flex whitespace-nowrap overflow-x-auto">
                    { qaPresetAttributesSelected.attributes.map((el, idx) =>
                        <div
                            className="description-tip-modal-sbm-container relative border-b-[#DEDED9] border-b-[4px] h-[68px] flex flex-auto items-center justify-center text-center max-md:px-[16px]"
                            key={idx} onMouseOver={itemHover} onMouseOut={()=>{ setModalStyle({ opacity: 0 }); setModalOverTop(false); }}
                        >
                            <span className='text-[#003578] font-semibold text-[15px] pointer-events-none'
                                dangerouslySetInnerHTML={{ __html: el.name }}
                            ></span>

                            <div
                                className="description-tip-modal-sbm description-tip-modal-sbm-desktop max-lg:!hidden have-triangle pointer-events-none text-align-left"
                                style={{ position: 'fixed', whiteSpace: 'initial', height: "min-content", ...modalStyle, gap: modalOverTop ? 6 : 12 }}
                            >
                                <p className="text-2xl font-bold">Interview Questions for { el.name }</p>
                                { el.questions.map((el2, idx2) => <span className="block" key={idx2} style={{ fontSize: modalOverTop ? 14 : 16 }}>
                                    Interview Question {idx2 + 1} : { el2.question }
                                </span>)}
                                <span className="description-tip-modal-sbm-triangle" style={triangleStyle}></span>
                            </div>

                            <div
                                className="description-tip-modal-sbm text-left max-lg:flex lg:!hidden"
                                style={{ position: 'fixed', width: 'calc(100% - 44px)', left: '22px', whiteSpace: 'initial' }}
                            >
                                <p className="text-2xl font-bold">Interview Questions for { el.name }</p>
                                { el.questions.map((el3, idx3) => <span className="text-[14px] block" key={idx3}>
                                    { el3.question }
                                </span>)}
                            </div>
                        </div>
                    )}
                    </div>

                </div> : null }

                <ContactUsLinkBlock setContactFormOpen={setContactFormOpen} topPaste={bottomPaste} />

                <hr className="mt-[80px] border-b border-neutral-200 md:hidden" />
            </div>
        </>
    )
}

export default Step6