import React, { useEffect, useState } from "react"

const ProgressBar = ({ completed, containerClasses = "" }) => {
    const containerStyles = {
        height: 4,
        width: '100%',
        backgroundColor: "#EFEEF0",
    }

    const fillerStyles = {
        height: '100%',
        width: `${completed}%`,
        backgroundColor: '#003578',
        borderRadius: 'inherit',
        textAlign: 'right',
        transition: 'width 1s ease-in-out',
    }

    const labelStyles = {
        padding: 5,
        color: 'white',
        fontWeight: 'bold'
    }

    return (
        <div style={containerStyles} className={containerClasses}>
            <div style={fillerStyles}>
                <span style={labelStyles}>{`${completed}%`}</span>
            </div>
        </div>
    );
}

const StepIndicatorItem = ({ stepNumber, label, isActive, stepClick }) => {
    const baseStyles = "justify-center items-center self-center w-10 h-10 text-xl leading-8 font-bold text-white border-4 border-white border-solid shadow-steps rounded-[360px]";
    const activeStyles = "bg-green-500 cursor-default";
    const inactiveStyles = "bg-neutral-200";

    return (
        <div id={"step_"+ stepNumber} className="flex flex-col flex-1 shrink-0 px-2">
            <div 
                className={`${baseStyles} ${isActive ? activeStyles : inactiveStyles}`}
                onClick={(e)=>{
                    e.preventDefault();
                    if(isActive){
                        stepClick(+stepNumber);
                    }
                }}
            >
                {stepNumber}
            </div>
            <div className="mt-3.5 text-base font-semibold text-neutral-600 items-center">
                {label.split("\n").map((line, index, arr) => (
                    <React.Fragment key={index}>
                        {line}{index < arr.length - 1 && <br />}
                    </React.Fragment>
                ))}
            </div>
        </div>
    );
};

export const HiringProcess = ({ stepsData = [], stepClick = ()=>{} }) => {
    const mob = document.body.offsetWidth < 1200;
    const activeCount = stepsData.filter(step => step.isActive).length
    const isLastStep = stepsData.length === activeCount.length
    // const steps = mob ? stepsData.slice(isLastStep ? activeCount - 2 : activeCount - 1, stepsData.length) : stepsData;
    
    // const progress = ((mob ? isLastStep ? 2 : 1 : activeCount) / steps.length) * 100;
    const steps = stepsData;
    const progress = (activeCount / steps.length) * 100;

    useEffect(() => {
        const currentEL = document.querySelector(`#step_${activeCount}`)
        const scrollLeft = currentEL.offsetLeft;
        currentEL.closest(".overflow-auto").scrollLeft = scrollLeft;
    },[stepsData])

    return (
        <div
            className={`grid-cols-1 relative flex-auto flex shrink-0 justify-between text-center max-lg:grid`}
            style={{
                gridTemplateColumns: `repeat(${steps.length}, minmax(0, 1fr))`
            }}
        >
            <ProgressBar
                containerClasses='absolute z-[-1] top-[18px]'
                completed={progress}
            />
            {steps.map((step, index) => (
                <StepIndicatorItem
                    key={index}
                    stepNumber={step.stepNumber}
                    label={step.label}
                    isActive={step.isActive}
                    stepClick={stepClick}
                />
            ))}
        </div>
    );
};