import React, { useEffect, useState } from "react";
import { EditableInput } from "./EditableInput";
import SelectBox from "../../components/base/SelectBox";
import PrimaryButton from "../../components/base/PrimaryButton";
import { ButtonOptions } from "./ButtonOptions";
import { ModalForm } from "./ModalForm";
import { AdditionalInputs } from "./AdditionalInputs";


export const ReferalSection = ({ payload, setPayload }) => {
    const [isEditing, setIsEditing] = useState(false);

    const [isModal, setIsModal] = useState(false)

    useEffect(() => {
        if (isEditing && (1024 > window.innerWidth)) {
            setIsModal(true)
        } else {
            setIsModal(false)
        }
    }, [isEditing]);

    const handleChange = (e) => {
        const { name, value } = e.target;

        setPayload({
            ...payload,
            applicationForm: {
                ...payload.applicationForm,
                [name]: value
            }
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault()
        setIsEditing(false)
    }

    const options = [
        {
            label: "Edit the title / texts...",
            handler: () => setIsEditing(true)
        },
        {
            label: payload.applicationForm.how_did_you_hear_about_this_job_active ? "Disable the entire section..." : "Enable the entire section...",
            handler: () => {
                setPayload({
                    ...payload,
                    applicationForm: {
                        ...payload.applicationForm,
                        how_did_you_hear_about_this_job_active: !payload.applicationForm.how_did_you_hear_about_this_job_active
                    }
                });
            }
        },
        {
            label: "The 2 choice setup is fixed...",
            handler: () => { }
        },
    ]


    if (isEditing && isModal) {

        return (
            <ModalForm onSave={(e) => { setIsModal(false); setIsEditing(false) }} formWrapClass="m-auto">
                <div className='w-full'>
                    <EditableInput
                        className="font-bold text-[22px] mb-2"
                        type="text"
                        name="how_did_you_hear_about_this_job_title"
                        id="how_did_you_hear_about_this_job_title"
                        value={payload.applicationForm.how_did_you_hear_about_this_job_title}
                        onChange={handleChange}
                        disabled={!isEditing}
                    />
                    <div className=" h-[54px] input-ap">
                        <SelectBox
                            options={payload.applicationForm.how_did_you_hear_about_this_job_choices.map(item => ({ label: item }))}
                            svg={2}
                        />
                    </div>

                    <AdditionalInputs btnName={'Add New'} isEditing={isEditing} isArrayData />

                </div>
            </ModalForm>
        )
    }

    return (
        <form onSubmit={handleSubmit} className='flex flex-col max-sm:gap-[24px] justify-between items-start w-full' >
            <div className="flex justify-between w-full">
                <EditableInput
                    className="font-bold lg:text-[22px] text-[20px] mb-2"
                    type="text"
                    name="how_did_you_hear_about_this_job_title"
                    id="how_did_you_hear_about_this_job_title"
                    value={payload.applicationForm.how_did_you_hear_about_this_job_title}
                    onChange={handleChange}
                    disabled={!isEditing}
                />
                {
                    isEditing ?
                        <PrimaryButton
                            type="submit"
                            className={"px-9 lg:h-[52px] lg:text-lg"}
                        >
                            Save
                        </PrimaryButton>
                        :
                        <ButtonOptions options={options} />
                }
            </div>
            {
                !isEditing ? <>
                    { payload.applicationForm.how_did_you_hear_about_this_job_active ? 
                    <div className='w-full max-sm:flex max-sm:gap-[24px] max-sm:flex-col'>
                        <div className="lg:w-[312px] h-[54px] input-ap">
                            <SelectBox 
                                options={payload.applicationForm.how_did_you_hear_about_this_job_choices.map(item => ({ label: item }))}
                                svg={2}
                            />
                        </div>
                    </div> : null }
                </> : <AdditionalInputs
                    className="w-full"
                    btnName={'Add New'}
                    isEditing={isEditing}
                    isArrayData
                    data={payload.applicationForm.how_did_you_hear_about_this_job_choices}
                    setData={(value)=>{
                        setPayload({
                            ...payload,
                            applicationForm: {
                                ...payload.applicationForm,
                                how_did_you_hear_about_this_job_choices: value
                            }
                        });
                    }}
                />
            }
            

        </form>
    )
}