import React from "react";
import { Footer } from "../components/parts/Footer";
import { useState } from "react";
import BreadCrumbWhite from "../components/BreadCrumbWhite";
import {crumbsConfig} from "./SBM01_Form/config";

const SBM01_2_3 = () => {
    const asigmantsData = [
        {
            id: '12234',
            title: 'Assessment 1',
            category: [
                { id: 'sdf', categoryName: "Category", value: 90 },
                { id: 'sdf2', categoryName: "Category", value: 90 },
                { id: 'sdf3', categoryName: "Category", value: 90 },
                { id: 'sdf4', categoryName: "Category", value: 90 },
                { id: 'sdf5', categoryName: "Category", value: 90 },
                { id: 'sdf6', categoryName: "Category", value: 90 },
            ],
            isActive: false
        },
        {
            title: 'Assessment 2',
            id: '67878',
            category: [
                { id: 'sdf7', categoryName: "Category", value: 90 },
                { id: 'sdf8', categoryName: "Category", value: 90 },
                { id: 'sdf9', categoryName: "Category", value: 90 },
                { id: 'sdf0', categoryName: "Category", value: 90 },
                { id: 'sdf-', categoryName: "Category", value: 90 },
                { id: 'sdf35', categoryName: "Category", value: 90 },
            ],
            isActive: true
        }
    ]
    // const categories1 = ["Category", "Category", "Category", "Category", "Category", "Category"];
    // const categories2 = ["Category", "Category", "Category", "Category", "Category", "Category"];
    const CategoryScore = ({ category, score }) => (
        <div className="flex gap-5 justify-between mt-6 max-w-full whitespace-nowrap w-[400px]">
            <div className="flex-auto my-auto text-xl font-bold leading-7 ">{category}</div>
            <div className="flex items-center justify-center px-2 py-1 lg:h-[54px] h-[52px] text-lg min-w-[96px] text-center bg-white border border-solid border-zinc-300 max-md:px-5">
                {score}%
            </div>
        </div>
    );
    const TotalScore = ({ title, score, isGreen }) => (
        <div className="flex gap-5 mt-6 max-w-full text-lg whitespace-nowrap w-[400px] text-center">
            <div className="grow flex justify-center items-center px-16 lg:h-[54px] h-[52px] font-semibold bg-neutral-100 w-fit">
                {title}
            </div>
            <div
                className="flex gap-2 justify-center items-center px-5 py-1 min-w-[96px] lg:h-[54px] h-[52px] bg-white border border-solid border-zinc-300"
            >
                {isGreen && (
                    <div className="shrink-0 my-auto w-3 h-3 bg-green-500 rounded-[360px]" />
                )}
                <div>{score}%</div>
            </div>
        </div>
    );
    const Assessment = ({ title, categories, totalScore, isGreen }) => (
        <>
            <div className="self-stretch mt-16 text-[28px] font-bold leading-8 max-md:mt-10 max-md:max-w-full">
                {title}
            </div>
            <div className="shrink-0 self-stretch mt-9 h-px border border-solid bg-neutral-200 border-neutral-200 max-md:max-w-full" />
            {categories.map((item, index) => (
                <CategoryScore key={item.id} category={item.categoryName} score={item.value} />
            ))}
            <TotalScore title="Total Score" score={90} isGreen={isGreen} />
        </>
    );
    return (
        <>

            <BreadCrumbWhite crumbs={[crumbsConfig.sub, crumbsConfig.jobs, crumbsConfig.applicant, crumbsConfig.spector, 'Assessment']} />
            <section className="flex justify-center flex-auto py-9 lg:py-11 w-full text-zinc-800 max-md:max-w-full max-md:pb-[80px]">
                <div className="flex flex-col w-full max-w-[1432px] px-4 max-md:max-w-full">
                    <div className="self-stretch text-4xl max-lg:text-center font-bold">Marc Spector</div>
                    {asigmantsData.map(item => (
                        <Assessment key={item.id} title={item.title} categories={item.category} isGreen={item.isActive} />
                    ))}
                </div>
            </section>
            <Footer hideCopyright={true} />
        </>
    )
}

export default SBM01_2_3