import { Footer } from "../components/parts/Footer";
import { Header } from "../components/parts/Header";
import BannerGift from "../components/parts/BannerGift";
import { NavLink, useOutletContext } from "react-router-dom";
import Breadcrumbs from "../components/base/Breadcrumbs";
import { useDispatch, useSelector } from "react-redux";
import { getCurrentPage, getCurrentPageLoading } from "../redux/slices/applicationSlice";
import findValue from "../assets/helpers/findValue";
import getImageUrl from "../assets/helpers/getImageUrl";
import PageLoader from "../components/PageLoader";
import bgImage from "../assets/img/bg-city.png";
import bgNew from "../assets/img/bg-new.png";

const HOM1_1 = () => {
    const currentPage = useSelector(getCurrentPage);
    const isLoading = useSelector(getCurrentPageLoading);
    const getValue = (key) => findValue(currentPage?.blocks, key);
    function ContentCard({ src, alt, title, description }) {
        return (
            <div className="flex flex-col lg:w-[33%] max-md:w-full max-md:ml-0">
                <div className="flex flex-col grow max-md:mt-8">
                    <div className="flex justify-center items-center self-center px-2 border-sky-900 border-dashed border-[1.5px] h-[132px] rounded-[360px] w-[132px]">
                        <img loading="lazy" src={src} alt={alt} className="w-full aspect-square rounded-[360px]" />
                    </div>
                    <h3 className="mt-6 text-2xl text-[22px] font-bold text-center text-zinc-800">{title}</h3>
                    <p className="mt-2 text-lg leading-7 text-center text-stone-500 font-[400]">{description}</p>
                </div>
            </div>
        );
    }

    return (
        <>

            <Header colorStyle={'black'} />
            {!currentPage ? <PageLoader /> :
                <>
            <section
                className={`
                box-border w-full bg-center bg-no-repeat bg-cover
                h-screen
                max-h-[786px]
                lg:pb-40 lg:pt-20 pt-10 pb-24
                `}
                style={{ backgroundImage: `url(${bgNew})` }}
            >
                <div className=" w-full max-w-[1070px] mx-auto px-5">
                    <div className="flex relative flex-col w-full">
                        {/* <Breadcrumbs links={[{ name: "Home", to: "/HOM1_1", isCurrent: true }]} /> */}
                        <main className="max-md:mt-10 max-md:max-w-full">
                            <div className="flex gap-11 max-lg:flex-col">
                                <div className="flex flex-col max-md:ml-0">
                                    <div className="flex flex-col w-full max-md:w-full">
                                        <h1 className="lg:text-[70px] font-bold text-white text-[40px] max-lg:leading-[48px] text-center leading-none">
                                            {getValue('home_title_top')}
                                        </h1>
                                        {/* <div className="mt-6 h-1 bg-[#37B34A] w-[78px] max-md:mx-[auto]" /> */}
                                        <p className="mt-5 lg:text-2xl lg:leading-10 text-lg text-white max-md:text-lg text-center">
                                            {getValue('home_description_top')}
                                        </p>
                                    </div>
                                    <NavLink
                                        to="/HOM1_5"
                                        tabIndex="0"
                                        className="flex justify-center items-center px-16 py-6 mt-8 lg:text-2xl text-[19px] max-lg:w-full font-bold whitespace-nowrap bg-[#37B34A] rounded-md w-[276px] max-md:px-5 h-[70px] text-white max-md:h-[60px] max-md:py-[10px] mx-auto"
                                    >
                                        {getValue('home_button_name_top')}
                                    </NavLink>
                                </div>
                                {/* <div className="flex flex-col lg:w-[45%] max-md:ml-0 max-md:w-full">
                                    {currentPage && <img
                                        loading="lazy"
                                        src={getImageUrl(getValue('home_right_image_top'))}
                                        alt="A visual representation of our services"
                                        className=" w-full aspect-[1.28] max-w-full"
                                    />}
                                </div> */}
                            </div>
                        </main>
                    </div>
                </div>

            </section>
            <section className=" w-full max-w-[1280px] mx-auto px-5 ">
                <main className="flex flex-col justify-center items-center max-w-full text-4xl font-bold leading-10 text-zinc-800 w-[1240px] md:px-5 md:mt-10 py-[90px] max-md:py-[80px]">
                    <section className="flex flex-col max-w-full w-[752px]">
                        <h2 className="max-md:text-[32px] max-md:leading-[48px] text-balance max-md:max-w-full text-center">
                            {getValue('home_title_block_ways')}
                        </h2>
                        <hr className="self-center mt-6 h-1 bg-[#37B34A] w-[78px]" />
                    </section>

                    <div className="self-center md:px-5 mt-14 max-w-full w-[1240px] max-md:mt-10">
                        <div className="flex gap-5 max-lg:flex-col items-center">
                            {getValue('items')?.data?.map((data, index) => (
                                <ContentCard key={index} src={getImageUrl(data.image_icon)} alt={data.title} title={data.title} description={data.description} />
                            ))}
                        </div>
                    </div>

                </main>
            </section>
            <BannerGift currentPage={currentPage}/>
            </>
                        }
            <Footer />
        </>
    )
}

export default HOM1_1