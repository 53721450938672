import React, { useEffect, useState } from "react";
import BreadCrumbWhite from "../components/BreadCrumbWhite";
import SelectField from "../components/base/SelectField";
import RejectCandidate from "../components/modals/RejectCandidate";
import { Footer } from "../components/parts/Footer";

import { format } from "date-fns";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams, useSearchParams } from "react-router-dom";
import api from "../api";
import PageLoader from "../components/PageLoader";
import Button from "../components/base/Button";
import ModalGrop from "../components/parts/ModalGrop";
import { getSubscriberRole } from "../redux/slices/applicationSlice";
import { getApplicantsFetch, getCandidatesFetch, getJobByIdFetch } from "../redux/thunks/jobsThunks";
import { crumbsConfig } from "./SBM01_Form/config";


const TableHeadintTH = ({ item, tableHeadingColsSort, setTableHeadingColsSort }) => (
    <th
        className={`text-base p-[13px] font-semibold whitespace-nowrap bg-neutral-100 text-zinc-800 ${item.hasOwnProperty('sort') ? 'cursor-pointer select-none' : ''}`}
        onClick={(e) => {
            e.preventDefault();
            if (item.hasOwnProperty('sort')) {
                setTableHeadingColsSort({
                    field: item.field,
                    sort: tableHeadingColsSort.sort === null ? 'asc' : tableHeadingColsSort.sort === 'asc' ? 'desc' : null
                })
            }
        }}
    >
        <div className="flex items-center justify-center gap-2">
            {item.title}

            {item.hasOwnProperty('sort') ?
                <svg width="13" height="22" viewBox="0 0 13 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                    {
                        tableHeadingColsSort?.sort === null || tableHeadingColsSort?.field !== item?.field || (tableHeadingColsSort?.field === item?.field && tableHeadingColsSort?.sort === "desc") ?
                            <path d="M2.4033 7.64344C2.43167 7.71196 2.47972 7.77054 2.54138 7.81176C2.60304 7.85298 2.67554 7.87498 2.74971 7.875L10.2497 7.875C10.3239 7.87506 10.3965 7.8531 10.4582 7.8119C10.5199 7.77069 10.568 7.71211 10.5964 7.64355C10.6248 7.57499 10.6323 7.49954 10.6178 7.42676C10.6033 7.35398 10.5675 7.28713 10.515 7.23469L6.76502 3.48469C6.73019 3.44982 6.68883 3.42216 6.64331 3.40329C6.59778 3.38442 6.54899 3.37471 6.49971 3.37471C6.45042 3.37471 6.40163 3.38442 6.3561 3.40329C6.31058 3.42216 6.26922 3.44982 6.23439 3.48469L2.48439 7.23469C2.43196 7.28716 2.39628 7.354 2.38184 7.42676C2.36741 7.49952 2.37488 7.57492 2.4033 7.64344Z" fill="#A9A9A9" />
                            : null}
                    {
                        tableHeadingColsSort?.sort === null || tableHeadingColsSort?.field !== item?.field || (tableHeadingColsSort?.field === item?.field && tableHeadingColsSort?.sort === "asc") ?
                            <g clip-path="url(#clip0_304_1751)">
                                <path d="M10.5967 14.3566C10.5683 14.288 10.5203 14.2295 10.4586 14.1882C10.397 14.147 10.3245 14.125 10.2503 14.125H2.7503C2.67608 14.1249 2.60352 14.1469 2.5418 14.1881C2.48008 14.2293 2.43197 14.2879 2.40356 14.3565C2.37515 14.425 2.36773 14.5005 2.38222 14.5732C2.39672 14.646 2.43248 14.7129 2.48498 14.7653L6.23498 18.5153C6.26981 18.5502 6.31117 18.5778 6.35669 18.5967C6.40222 18.6156 6.45101 18.6253 6.5003 18.6253C6.54958 18.6253 6.59837 18.6156 6.6439 18.5967C6.68942 18.5778 6.73078 18.5502 6.76561 18.5153L10.5156 14.7653C10.568 14.7128 10.6037 14.646 10.6182 14.5732C10.6326 14.5005 10.6251 14.4251 10.5967 14.3566Z" fill="#A9A9A9" />
                            </g>
                            : null}

                    <defs>
                        <clipPath id="clip0_304_1751">
                            <rect width="12" height="12" fill="white" transform="translate(0.5 10)" />
                        </clipPath>
                    </defs>
                </svg> : null}
        </div>
    </th>
);

const CandidateProgress = ({ color, percentage }) => (
    <div className="flex justify-center gap-2.5 self-stretch my-auto text-base whitespace-nowrap text-zinc-800">
        <div className={`shrink-0 w-5 h-5 ${color} rounded-[360px]`} />
        <div className="my-auto">{percentage}</div>
    </div>
);

const ActionButton = ({ onClick, text, bgColor }) => (
    <button type="button" className={`grow justify-center px-6 py-3 ${bgColor} rounded-md max-md:px-5`} onClick={onClick}>{text}</button>
);
const CheckboxInput = ({ label, checked = false, onChange, className = 'h-[18px] w-[18px]' }) => {
    const [isChecked, setIsChecked] = useState(checked);

    const handleChange = () => {
        const newValue = !isChecked
        // setIsChecked(newValue)
        onChange && onChange(newValue)
    }
    useEffect(() => {
        setIsChecked(checked)
    }, [checked])
    return (
        <label className="flex gap-3.5 cursor-pointer">
            <div className={`flex justify-center items-center px-0.5 my-auto border border-solid bg-stone-50 border-stone-300 ${className} `}>
                <input
                    type="checkbox"
                    checked={isChecked}
                    onChange={handleChange}
                    className="hidden"
                />
                {
                    isChecked ?
                        <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M12.1499 2.5461L11.1844 1.88985C10.9173 1.70896 10.5513 1.77838 10.3725 2.0434L5.63995 9.02237L3.46507 6.84749C3.23791 6.62032 2.86771 6.62032 2.64055 6.84749L1.81393 7.67411C1.58676 7.90127 1.58676 8.27146 1.81393 8.50073L5.15828 11.8451C5.34548 12.0323 5.63995 12.1753 5.90497 12.1753C6.17 12.1753 6.43712 12.0091 6.6096 11.7588L12.3055 3.3559C12.4864 3.09088 12.417 2.72699 12.1499 2.5461Z" fill="#37B34A" />
                        </svg>
                        :
                        null
                }
            </div>
            {label && <div className="flex-auto text-lg leading-8 whitespace-nowrap text-neutral-500">
                {label}
            </div>}
        </label>
    )
};
const CandidateRow = ({ onClick, isCheck, name, date, source, application, resume, coverLetter, progressColor, progressPercentage, jobId, applicationId, handleAdvance, onCheckboxChange }) => (
    <tr>
        <td className="text-lg px-[13px] py-[22px] text-center whitespace-nowrap">
            <CheckboxInput onChange={onCheckboxChange} checked={isCheck} className="w-[24] h-[24px] min-w-[24px]" />
        </td>
        <td className="text-lg px-[13px] py-[22px] text-center whitespace-nowrap">
            <Link to={'/SBM01_2_3'} className="text-lg font-bold text-green-500">{name}</Link>
        </td>
        <td className="text-lg px-[13px] py-[22px] text-center whitespace-nowrap">
            <div className=" text-stone-500">{date}</div>
        </td>
        <td className="text-lg px-[13px] py-[22px] text-center whitespace-nowrap">
            <div className="text-stone-500">{source}</div>
        </td>
        <td className="text-lg px-[13px] py-[22px] text-center whitespace-nowrap">
            <Link to={`/SBM01_2_1/${jobId}/${applicationId}`} className="font-bold text-green-500">{application}</Link>
        </td>
        <td className="text-lg px-[13px] py-[22px] text-center whitespace-nowrap">
            <Link to={'/SBM01_2_2'} className="font-bold text-green-500">{resume}</Link>
        </td>
        <td className="text-lg px-[13px] py-[22px] text-center whitespace-nowrap">
            <CandidateProgress color={progressColor} percentage={progressPercentage} />
        </td>
        <td className="text-lg px-[13px] py-[22px] text-center whitespace-nowrap">
            <div className="text-base text-zinc-800">{coverLetter}</div>
        </td>
        <td className="text-lg px-[13px] py-[22px] text-center whitespace-nowrap">
            <div className="flex items-center gap-[8px] font-semibold text-white ">
                <ActionButton text="Reject" bgColor="bg-red-600" onClick={onClick} />
                <ActionButton text='Advance' bgColor="bg-green-500" onClick={handleAdvance} />
            </div>
        </td>
    </tr>
);
const InterviewStage = ({ stageName, isActive }) => {
    return (
        <div className={`grow flex justify-center items-center text-[16px] font-semibold px-[10px] py-[30px] min-w-[173px] text-center border-gray-200 border-solid ${isActive ? "border-sky-900 text-neutral-500" : "border-gray-200"} border-b-[5px] max-md:px-5`}>
            <span>{stageName}</span>
        </div>
    );
};
const CandidateCard = ({ isCheck, name, date, id }) => {
    const { jobId } = useParams();
    return (
        <section className="flex justify-center mt-[36px]  max-md:mt-10">
            <div className="flex items-center justify-center p-[13px] min-w-[50px] ">
                <CheckboxInput checked={isCheck} className="w-[24] h-[24px] min-w-[24px]" />
            </div>
            <div className="flex flex-auto gap-5 px-5 py-5 text-lg items-center">
                <Link to={`/SBM01_10_14(2)/${jobId}/${id}`} className="font-bold text-green-500">{name}</Link>
                <div className="flex-auto text-stone-500 max-md:max-w-full">Applied Date {date}</div>
            </div>
        </section>
    );
};

const SBM01_2 = () => {
    const [filters, setFilters] = useState({
        limit: '10',
        status: 'open',
        sort_by: 'date'
    });
    const [search, setSearch] = useState("")

    const [searchParams, setSearchParams] = useSearchParams()
    const [visibleTab, setVisibleTab] = useState(1);
    const tab = searchParams.get('tab');
    const { jobId } = useParams();
    const dispatch = useDispatch();
    const { job, isLoading, candidates, applicants } = useSelector(state => state.jobs);
    const subscriberRole = useSelector(getSubscriberRole);

    const [rejectPopup, setRejectPopup] = useState(false);
    const [rejectPopupPayload, setRejectPopupPayload] = useState({
        jobId: null,
        applicationId: null,
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFilters(prevState => ({ ...prevState, [name]: value }));

        setSearchParams(prev => {
            prev.set(name, value)
            return prev;
        })
    }

    const statusArr = [
        { value: 'approved', label: 'Approved' },
        { value: 'pending', label: 'Pending' },
        { value: 'rejected', label: 'Rejected' },
    ];

    const showTableItems = [
        { value: '10', label: '10' },
        { value: '20', label: '20' },
        { value: '50', label: '50' },
    ];

    const sortBy = [
        { value: 'date', label: 'Date' },
        { value: 'name', label: 'Name' },
    ];


    const tableHeadingCols = [
        { title: 'Full Name', field: 'fullName', sort: true },
        { title: 'Date of Application', field: 'createdAt', sort: true },
        { title: 'Source', field: 'source', sort: true },
        { title: 'Application' },
        { title: 'Resume' },
        { title: 'Assessment' },
        { title: 'Cover Letter' }
    ];

    const [tableHeadingColsSort, setTableHeadingColsSort] = useState({
        field: '',
        sort: null
    });


    const applicantsFiltered = tableHeadingColsSort.sort && tableHeadingColsSort.field && applicants?.length ? [...applicants].sort(function (a, b) {
        if (tableHeadingColsSort.sort === 'asc') {
            if (a[tableHeadingColsSort.field] < b[tableHeadingColsSort.field]) return -1;
            if (a[tableHeadingColsSort.field] > b[tableHeadingColsSort.field]) return 1;
        } else {
            if (a[tableHeadingColsSort.field] < b[tableHeadingColsSort.field]) return 1;
            if (a[tableHeadingColsSort.field] > b[tableHeadingColsSort.field]) return -1;
        }
        return 0;
    }) : applicants;


    const interviewStages = [
        "Clarification",
        "1st Interview",
        "Post Interview Survey",
        "2nd Interview",
        "Post 2nd Interview Doc Review",
        "AI Evaluation",
        "Training Insights",
        "Offer Meeting",
    ];

    const crumbs = visibleTab === 1 ?
        [crumbsConfig.sub, crumbsConfig.jobs, ['Applicants', `/SBM01_2/${jobId}?tab="applicants`]]
        : [crumbsConfig.sub, crumbsConfig.jobs, ...(subscriberRole !== "interviewer" ? [['Applicants', `/SBM01_2/${jobId}?tab="applicants`]] : []), ['Candidates', `/SBM01_2/${jobId}?tab="candidates`]]

    const advanceApplication = async (applicationId) => {
        const res = await api.jobApplications.setApplicationStatus(jobId, applicationId, {
            status: 'approved'
        })
        if (res.success) {
            dispatch(getCandidatesFetch(jobId))
            dispatch(getApplicantsFetch(jobId))
            setSearchParams(prev => ({ ...prev, 'tab': 'candidates' }));
        }
    }

    useEffect(() => {
        if (!jobId) return;
        dispatch(getJobByIdFetch(jobId))
    }, [jobId, dispatch]);

    useEffect(() => {
        if (tab === "applicants") {
            dispatch(getApplicantsFetch(`${jobId}?${searchParams.toString()}`))
        } else {
            dispatch(getCandidatesFetch(`${jobId}?${searchParams.toString()}`))
        }
    }, [jobId, filters, searchParams])

    useEffect(() => {
        if (tab === "applicants") {
            setVisibleTab(1)
        } else if (tab === "candidates") {
            setVisibleTab(2);
        } else {
            setSearchParams(prev => ({ ...prev, 'tab': 'applicants' }));
        }
    }, [tab, setSearchParams]);


    useEffect(() => {
        if (subscriberRole !== "interviewer") return
        setVisibleTab(2)
        setSearchParams(prev => ({ ...prev, 'tab': 'candidates' }));
    }, [subscriberRole])


    const [selectedApplicant, setSelectedApplicant] = useState()

    return (
        <>
            <BreadCrumbWhite crumbs={crumbs} />
            {
                job && !isLoading ?

                    <section className="flex justify-center items-center px-16 py-12 mt-1.5 w-full flex-auto text-zinc-800 max-md:px-5 max-md:max-w-full">
                        <div className="flex flex-col my-10 w-full max-w-[1400px] max-md:max-w-full">
                            <h1 className="w-full text-4xl font-bold text-zinc-800 max-md:max-w-full">
                                {job.jobTitle}
                            </h1>
                            <div className="flex gap-5 self-start mt-3 text-base text-stone-500 mb-[44px]">
                                <div className="flex items-center gap-1.5">
                                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M9.99999 1.66602C6.32415 1.66602 3.33332 4.65685 3.33332 8.32852C3.30915 13.6993 9.74665 18.1527 9.99999 18.3327C9.99999 18.3327 16.6908 13.6993 16.6667 8.33268C16.6667 4.65685 13.6758 1.66602 9.99999 1.66602ZM9.99999 11.666C8.15832 11.666 6.66665 10.1743 6.66665 8.33268C6.66665 6.49102 8.15832 4.99935 9.99999 4.99935C11.8417 4.99935 13.3333 6.49102 13.3333 8.33268C13.3333 10.1743 11.8417 11.666 9.99999 11.666Z" fill="#37B34A" />
                                    </svg>
                                    <span className="leading-5">{job.city}, {job.state}</span>
                                </div>
                                <div className="flex items-center gap-0.5 pr-3 leading-5">
                                    <div className="flex-auto">
                                        <span className="font-bold">Status: </span>
                                        <select name="status" id="status" value={filters.status} className="pr-[20px]" onChange={handleChange} style={{
                                            backgroundPosition: 'right 0 center',
                                            backgroundImage: `url('data:image/svg+xml,<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M6.66675 8.33398L10.0001 11.6673L13.3334 8.33398" stroke="%2337B34A" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg>')`,
                                            backgroundSize: '20px'
                                        }}>
                                            {statusArr.map(item => (
                                                <option key={item.value} value={item.value}>{item.label}</option>
                                            ))}
                                        </select>
                                    </div>
                                </div>
                            </div>
                            {
                                subscriberRole !== "interviewer" ?
                                    <div className="flex gap-5 justify-between flex-wrap mb-[32px]">
                                        <div className="flex gap-5 justify-between my-auto text-xl font-semibold text-center whitespace-nowrap text-zinc-800">
                                            <Link to={{
                                                search: '?tab=applicants'
                                            }}>
                                                <button type="button" className={`grow justify-center px-5 h-[46px] ${visibleTab === 1 ? 'border-green-500' : 'border-white'} border-solid border-b-[5px] hover:bg-green-200`}>
                                                    Applicants
                                                </button>
                                            </Link>
                                            <Link to={{
                                                search: '?tab=candidates'
                                            }}>
                                                <button type="button" className={`grow  justify-center px-5 h-[46px] ${visibleTab === 2 ? 'border-green-500' : 'border-white'}  border-solid border-b-[5px] hover:bg-green-200`}>
                                                    Candidates
                                                </button>
                                            </Link>

                                        </div>
                                        <div className="flex max-w-[500px] flex-auto gap-3 text-lg max-md:flex-wrap">
                                            <form className="flex flex-auto items-center gap-[16px] max-sm:flex-wrap">
                                                <input type="text"
                                                    className="flex-auto pr-5 pl-[56px] py-2 bg-white rounded-md border border-solid border-zinc-300 text-stone-500 min-h-[64px]"
                                                    placeholder="Search applicants for this job..."
                                                    value={search}
                                                    onChange={(e) => setSearch(e.target.value)}
                                                    style={{
                                                        backgroundImage: `url('data:image/svg+xml,<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><circle cx="11.0588" cy="11.058" r="7.06194" stroke="%23666666" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/><path d="M20.0034 20.0024L16.0518 16.0508" stroke="%23666666" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg>')`,
                                                        backgroundPosition: 'left 18px center',
                                                        backgroundRepeat: 'no-repeat',
                                                        backgroundSize: '24px'
                                                    }} />
                                                <Button
                                                    type="button"
                                                    className={'text-white bg-green-500 min-w-[130px] min-h-[64px] max-sm:w-full'}
                                                    onClick={() => handleChange({ target: { name: "search", value: search } })}
                                                >
                                                    Find
                                                </Button>
                                            </form>
                                        </div>
                                    </div>
                                    :
                                    <h2 className="w-full text-2xl font-bold text-zinc-800 max-md:max-w-full mb-[32px]">Candidates</h2>
                            }
                            <div className="flex gap-5 justify-between p-[18px] text-base whitespace-nowrap bg-gray-200 flex-wrap">
                                <div className={`flex gap-2 flex-wrap font-semibold text-sky-900 ${visibleTab === 1 ? '' : 'hidden'}`}>

                                    <ModalGrop selectedApplicant={selectedApplicant} theme="2" />
                                </div>
                                <div className="flex gap-4 ml-auto max-sm:ml-0 max-sm:w-full">
                                    <div className="flex gap-3 max-sm:flex-col max-sm:flex-1">
                                        <div className="grow my-auto font-bold uppercase text-stone-500">Show</div>
                                        <SelectField
                                            // label="Job"
                                            inputBg="bg-[#FFFFFF] border border-solid border-[#DEDED9] min-w-max pl-[16px] pr-[40px] h-[50px]"
                                            name="limit"
                                            id="limit"
                                            value={filters.limit}
                                            parentClass='flex-auto mb-0'
                                            onChange={handleChange}
                                            options={showTableItems}
                                        />
                                    </div>
                                    {/* Backend TODO SORT BY */}
                                    {/* <div className="flex gap-3 max-sm:flex-col max-sm:flex-1">
                                        <div className="grow my-auto font-bold uppercase text-stone-500">Sort By</div>
                                        <SelectField
                                            // label="Job"
                                            inputBg="bg-[#FFFFFF] border border-solid border-[#DEDED9] min-w-max pl-[16px] pr-[40px] h-[50px]"
                                            name="sort_by"
                                            id="sort_by"
                                            parentClass='flex-auto mb-0'
                                            value={filters.sort_by}
                                            onChange={handleChange}
                                            options={sortBy}
                                        />
                                    </div> */}
                                </div>
                            </div>
                            <div className={`mt-[14px] overflow-auto ${visibleTab === 1 ? '' : 'hidden'}`}>
                                <table className="w-full">
                                    <thead>
                                        <tr>
                                            <th
                                                className="text-base font-semibold whitespace-nowrap bg-neutral-100 text-zinc-800"
                                                style={{
                                                    width: '10px'
                                                }}
                                            ></th>
                                            {tableHeadingCols.map((tableTh, index) => (
                                                <TableHeadintTH
                                                    item={tableTh}
                                                    key={index}
                                                    // change={(value)=>{
                                                    //     setTableHeadingCols((prev)=>{
                                                    //         return [...prev]?.map((item, itemIndex) => itemIndex === index ? value : item)
                                                    //     });
                                                    // }}
                                                    tableHeadingColsSort={tableHeadingColsSort}
                                                    setTableHeadingColsSort={setTableHeadingColsSort}
                                                />
                                            ))}
                                            <th
                                                className="text-base font-semibold whitespace-nowrap bg-neutral-100 text-zinc-800"
                                                style={{
                                                    width: '10px'
                                                }}
                                            ></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            applicantsFiltered?.length ? applicantsFiltered.map((data, index) => (
                                                <CandidateRow
                                                    key={index}
                                                    onClick={() => {
                                                        setRejectPopupPayload({
                                                            jobId,
                                                            applicationId: data.id
                                                        });
                                                        setRejectPopup(true);
                                                    }}
                                                    name={data.fullName}
                                                    application='Application'
                                                    resume='Resume'
                                                    progressColor='bg-green-600'
                                                    progressPercentage='100%'
                                                    coverLetter='–'
                                                    jobId={jobId}
                                                    applicationId={data.id}
                                                    date={format(data.createdAt, 'dd/MM/yy')}
                                                    source={data.sourse || 'Career Site'}
                                                    handleAdvance={() => advanceApplication(data.id)}
                                                    isCheck={selectedApplicant?.id === data.id}
                                                    onCheckboxChange={(checked) => {
                                                        if (checked) {
                                                            setSelectedApplicant(data)
                                                        }
                                                        else {
                                                            setSelectedApplicant(null)
                                                        }
                                                    }}
                                                />
                                            )) : <tr><td colSpan={9}><p className="flex justify-center items-center text-2xl font-bold py-4">No Applicants</p></td></tr>
                                        }
                                    </tbody>
                                </table>
                            </div>
                            <div className={`${visibleTab === 2 ? '' : 'hidden'}`}>
                                <div className="flex flex-col">
                                    {
                                        candidates?.length ? candidates?.map((candidate, index) => (
                                            <React.Fragment key={index}>
                                                <CandidateCard
                                                    date={format(candidate.createdAt, 'MM/dd/yyyy')}
                                                    name={candidate.fullName}
                                                    id={candidate.id}
                                                />
                                                <div className="flex gap-0.5 text-base font-semibold text-neutral-400 w-full overflow-auto">
                                                    {interviewStages.map((stage, i) => (
                                                        <InterviewStage key={i} stageName={stage} isActive={i === 0} />
                                                    ))}
                                                </div>
                                            </React.Fragment>
                                        ))
                                            :
                                            <p className="flex justify-center items-center text-2xl font-bold py-4">No Candidates</p>
                                    }
                                </div>
                            </div>
                        </div>
                    </section>
                    : isLoading ?
                        <PageLoader />
                        :
                        <h2 className="text-4xl font-bold text-center">Not found</h2>
            }
            {rejectPopup ?
                <RejectCandidate
                    open={rejectPopup}
                    rejectPopupPayload={rejectPopupPayload}
                    handleClose={() => {
                        setRejectPopup(false);
                        setRejectPopupPayload({
                            jobId: null,
                            applicationId: null
                        });
                    }}
                />
                : null}
            {/* {callPopup && <CallModal open={callPopup} tableHeding={true} data={modalsDataCall} handleClose={() => setCallPopup(false)} />} 
            {smsPopup && <SmsModal open={smsPopup} data={modalsDataSms} handleClose={() => setSmsPopup(false)} />}
            {emailPopup && <EmailModal open={emailPopup} data={modalsDataEmail} handleClose={() => setEmailPopup(false)} />}
            {addTagPopup && <AddTagModal open={addTagPopup} modalSize='modal__dialog--1000' handleClose={() => setAddTagPopup(false)} />} */}
            <Footer hideCopyright={true} />
        </>
    )
}

export default SBM01_2