const jobsBase = 'api/jobs';
const apiBase = 'api';

export const jobs = (instance) => ({
    async getAllJobs() {
        const { data } = await instance.get(`${jobsBase}`);
        return data;
    },
    async getJobById(jobId) {
        const { data } = await instance.get(`${jobsBase}/${jobId}`);
        return data;
    },
    async getJobsById(jobId) {
        const { data } = await instance.get(`${jobsBase}/public/${jobId}`);
        return data;
    },
    async cancelJobApplication(payload) {
        const { jobId, applicationId } = payload
        const { data } = await instance.get(`${jobsBase}/${jobId}/${applicationId}/cancel`);
        return data;
    },
    async getCandidates(payload) {
        const { jobId, queries } = payload
        const { data } = await instance.get(`${jobsBase}/${jobId}/candidates?${queries}`);
        return data;
    },
    async getJobsCompanies(payload) {
        const { companyId, ...rest } = payload
        const { data } = await instance.post(`${jobsBase}/company/${companyId}`, rest);
        return data;
    },
    async changeJob(payload) {
        const { jobId, rest } = payload;
        const { data } = await instance.put(`${jobsBase}/${jobId}`, rest);
        return data;
    },
    async deleteJob(payload) {
        const { jobId } = payload
        const { data } = await instance.delete(`${jobsBase}/${jobId}`);
        return data;
    },

    async getIndustriesList() {
        const { data } = await instance.get(`${apiBase}/industries?limit=1000`);
        return data;
    },
    async getJobPipelinesList() {
        const { data } = await instance.get(`${apiBase}/job-pipelines/list`);
        return data;
    },
    async getJobPipelinesListByIndustryId(industryId) {
        const { data } = await instance.get(`${apiBase}/job-pipelines/job-create/${industryId}`);
        return data;
    },
    async getQaPresetAttributesListByIndustryId(industryId) {
        const { data } = await instance.get(`${apiBase}/qa-preset-attributes/by-industry/${industryId}?append_questions=true`);
        return data;
    },
    async createJobToCompany(payload) {
        const { companyId, rest } = payload;
        const { data } = await instance.post(`${jobsBase}/company/${companyId}`, rest);
        return data;
    },
    async getJobsByCompany(payload) {
        const { companyId, filters = '' } = payload;
        // ?city=Kyiv&industrieId=1
        const { data } = await instance.get(`${jobsBase}/by-company/${companyId}?${filters}`);
        return data;
    },
    async getCitiesByCompany(payload) {
        const { companyId } = payload;
        const { data } = await instance.get(`${jobsBase}/cities-by-company/${companyId}`);
        return data;
    },

    async getPublicJob(id) {
        const { data } = await instance.get(`${jobsBase}/public/${id}`);
        return data;
    }
});