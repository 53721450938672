import { useEffect, useState } from "react";
import { useNavigate, useOutletContext } from "react-router-dom";
import InputField from "../../../components/base/InputField";
import { NextStepSectionSBM01 } from "../base/NextStepSectionSBM01";

const FinalHiringDecision = () => {
	const navigate = useNavigate()
	const { job, application, jobId, applicationId, setActiveStep } = useOutletContext()
	const [finalRecommendation, setFinalRecommendation] = useState(false)

	useEffect(() => {
		setActiveStep(8)
	}, [])

	useEffect(() => {
		window.scroll({ top: 0, behavior: "smooth" })
	}, []);

	const handlePrev = () => {
		navigate(`/SBM01_10_5/${jobId}/${applicationId}`)
	}
	const handleNext = () => {
		navigate(`/SBM01_10_6/${jobId}/${applicationId}`)
	}

	const aiReacomendation = [
		{
			label: "High Standard",
			hire: true
		},
		{
			label: "Medium Standard",
			hire: false
		},
		{
			label: "Average Standard",
			hire: true
		},
	]

	return (
		<div className="flex-auto">
			<div className="flex flex-col gap-11 flex-auto">
				<div className="border-b border-neutral-200 pb-9">
					<span className="text-3xl font-bold">Final Hiring Decisions</span>
				</div>

				<div className="pb-[60px] border-b border-neutral-200">
					<span className="block text-[22px] font-bold mb-4">Top 3 Strengths:</span>

					<div className="flex max-lg:justify-between gap-6 lg:gap-[114px] items-end">
						<div className="text-xl whitespace-nowrap lg:text-xl font-semibold flex items-center">
							<div className="green-box mr-4"></div>
							Category 1:
						</div>

						<div className="w-[180px] whitespace-nowrap lg:w-[200px]">
							<InputField label={'Positive Variance %'}
								labelClassName={'font-semibold text-[#6F6F6F]'}
								inputBg="bg-[#FFFFFF] border border-[#DEDED9]"
								parentClassName={'!mb-0'}
							/>
						</div>

					</div>
					<div className="flex max-lg:justify-between gap-6 lg:gap-[114px] items-end mt-7">
						<div className="text-xl whitespace-nowrap lg:text-xl font-semibold flex items-center">
							<div className="green-box mr-4"></div>
							Category 1:
						</div>

						<div className="w-[180px] whitespace-nowrap lg:w-[200px]">
							<InputField label={'Positive Variance %'}
								labelClassName={'font-semibold text-[#6F6F6F]'}
								inputBg="bg-[#FFFFFF] border border-[#DEDED9]"
								parentClassName={'!mb-0'}
							/>
						</div>

					</div>
					<div className="flex max-lg:justify-between gap-6 lg:gap-[114px] items-end mt-7">
						<div className="text-xl whitespace-nowrap lg:text-xl font-semibold flex items-center">
							<div className="green-box mr-4"></div>
							Category 1:
						</div>

						<div className="w-[180px] whitespace-nowrap lg:w-[200px]">
							<InputField label={'Positive Variance %'}
								labelClassName={'font-semibold text-[#6F6F6F]'}
								inputBg="bg-[#FFFFFF] border border-[#DEDED9]"
								parentClassName={'!mb-0'}
							/>
						</div>

					</div>


				</div>

				<div className="pb-[60px] border-b border-neutral-200">
					<span className="block text-[22px] font-bold mb-4">Top 3 Weaknesses:</span>

					<div className="flex max-lg:justify-between gap-6 lg:gap-[114px] items-end">
						<div className="text-xl whitespace-nowrap lg:text-xl font-semibold flex items-center">
							<div className="green-box mr-4"></div>
							Category 1:
						</div>

						<div className="w-[180px] whitespace-nowrap lg:w-[200px]">
							<InputField label={'Positive Variance %'}
								labelClassName={'font-semibold text-[#6F6F6F]'}
								inputBg="bg-[#FFFFFF] border border-[#DEDED9]"
								parentClassName={'!mb-0'}
							/>
						</div>

					</div>
					<div className="flex max-lg:justify-between gap-6 lg:gap-[114px] items-end mt-7">
						<div className="text-xl whitespace-nowrap lg:text-xl font-semibold flex items-center">
							<div className="green-box mr-4"></div>
							Category 1:
						</div>

						<div className="w-[180px] whitespace-nowrap lg:w-[200px]">
							<InputField label={'Positive Variance %'}
								labelClassName={'font-semibold text-[#6F6F6F]'}
								inputBg="bg-[#FFFFFF] border border-[#DEDED9]"
								parentClassName={'!mb-0'}
							/>
						</div>

					</div>
					<div className="flex max-lg:justify-between gap-6 lg:gap-[114px] items-end mt-7">
						<div className="text-xl whitespace-nowrap lg:text-xl font-semibold flex items-center">
							<div className="green-box mr-4"></div>
							Category 1:
						</div>

						<div className="w-[180px] whitespace-nowrap lg:w-[200px]">
							<InputField label={'Positive Variance %'}
								labelClassName={'font-semibold text-[#6F6F6F]'}
								inputBg="bg-[#FFFFFF] border border-[#DEDED9]"
								parentClassName={'!mb-0'}
							/>
						</div>

					</div>


				</div>

				<div className="pb-4 flex flex-col gap-7">
					<span className="block text-3xl font-bold">AI Recommendations:</span>
					{
						aiReacomendation?.length ? aiReacomendation.map((item, index) => (
							<div className="flex items-center" key={index}>
								<div className="lg:text-xl text-base font-bold w-[240px]">
									{item?.label}
								</div>
								<div className="flex items-center gap-3">
									<span
										className={`${item?.hire ? "bg-[#37B34A]" : "bg-[#B2B2B2]"} rounded-[4px] w-20 lg:w-[90px] h-[38px] flex items-center justify-center`}
									>
										<span className="font-bold text-[#FFFFFF]">Hire</span>
									</span>
									<span
										className={`${!item?.hire ? "bg-[#37B34A]" : "bg-[#B2B2B2]"} rounded-[4px] w-20 lg:w-[90px] h-[38px] flex items-center justify-center`}
									>
										<span className="font-bold text-[#FFFFFF]">Not Hire</span>
									</span>
								</div>

							</div>
						))
							: null
					}
				</div>
				<div className="pb-[60px]">
					<span className="block text-3xl font-bold mb-7">Final Recommendation:</span>
					<div className="flex items-center mt-7">
						<div className="flex items-center gap-3">
							<label
								className={`${finalRecommendation ? "bg-[#37B34A]" : "bg-[#B2B2B2]"} rounded-[4px] w-24 cursor-pointer lg:w-[120px] h-[54px] flex items-center justify-center`}
							>
								<input
									type="radio"
									hidden
									className="hidden"
									checked={finalRecommendation}
									name="finalRecommendation"
									onChange={(e) => setFinalRecommendation(true)}
								>
								</input>
								<span className="font-bold text-[#FFFFFF]">Hire</span>
							</label>
							<div className="italic text-[#999999] font-light">or</div>
							<label
								className={`${!finalRecommendation ? "bg-[#37B34A]" : "bg-[#B2B2B2]"} rounded-[4px] w-24 cursor-pointer lg:w-[120px] h-[54px] flex items-center justify-center`}
							>
								<input
									type="radio"
									hidden
									checked={!finalRecommendation}
									className="hidden"
									name="finalRecommendation"
									onChange={(e) => setFinalRecommendation(false)}
								>
								</input>
								<span className="font-bold text-[#FFFFFF]">Not Hire</span>
							</label>
						</div>
					</div>
				</div>
			</div>
			<NextStepSectionSBM01 prev={handlePrev} next={handleNext} />
		</div>
	)
}


export default FinalHiringDecision