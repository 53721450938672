import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useOutletContext } from 'react-router-dom';
import { FitScaleEvaluationTable } from "../../../components/base/FitScaleEvaluationTable";
import InputField from "../../../components/base/InputField";
import PrimaryButton from "../../../components/base/PrimaryButton";
import TextareaField from "../../../components/base/TextareaField";
import { createJobRatingsFetch, getAllJobRatingsFetch, updateJobRatingsFetch } from "../../../redux/thunks/jobRatingsThunks";
import { NextStepSectionSBM01 } from "../base/NextStepSectionSBM01";


const RatingOfTheJob = () => {
	const navigate = useNavigate()
	const { job, application, jobId, applicationId, setActiveStep } = useOutletContext()
	const dispatch = useDispatch();
	const { jobRatings, isLoading } = useSelector(state => state.jobRatings);
	useEffect(() => {
		setActiveStep(6)
	}, [])

	const [formData, setFormData] = useState({
		"question": "What is the applicant's rating of the job?",
		"score": 1,
		"comment": "",
		"reasonsNotHigher": [
			"",
			"",
			""
		],
		"reasonsNotLower": [
			"",
			"",
			""
		]
	})
	const handleChange = (name, value) => {
		setFormData(prev => ({ ...prev, [name]: value }))
	}
	const handleArrayChange = (arrayName, index, event) => {
		const newArray = [...formData[arrayName]];
		newArray[index] = event.target.value;
		handleChange(arrayName, newArray);
	};

	useEffect(() => {
		dispatch(getAllJobRatingsFetch({ applicationId }))
	}, [])

	useEffect(() => {
		if (!jobRatings.length) return
		setFormData(prev => ({ ...prev, ...jobRatings[0] }))
	}, [jobRatings])


	const handlePrev = () => {
		navigate(`/SBM01_10_4/${jobId}/${applicationId}`)
	}
	const handleNext = () => {
		navigate(`/SBM01_10_5/${jobId}/${applicationId}`)
	}

	const handleSubmit = () => {
		if (jobRatings?.length) {
			dispatch(updateJobRatingsFetch({
				applicationId,
				body: {
					items: [formData]
				}
			}))
		} else {
			dispatch(createJobRatingsFetch({
				applicationId,
				body: {
					items: [formData]
				}
			}))
		}
	}

	return (
		<div className='flex-auto'>
			<div className='flex flex-col gap-[40px] md:gap-[60px] max-w-[1432px] px-4 w-full pt-14 pb-24 mx-auto'>
				<div className="border-b border-neutral-200 pb-[24px] md:pb-9">
					<span className="text-[28px] md:text-3xl font-bold">Rating of the Job</span>
				</div>
				<div className="flex flex-col gap-8 pb-[50px] border-b border-neutral-200">
					<div>
						<span className="font-semibold text-[18px] block mb-8">{formData.question}</span>
						<FitScaleEvaluationTable
							labelLow="Poor Fit"
							labelHigh="Perfectly Fit"
							value={formData.score}
							onChange={(value) => handleChange("score", value)}
						/>
					</div>

					<div className="max-w-[640px] w-full">
						<label className="font-semibold text-[18px] mb-4 block">Comments</label>
						<TextareaField
							className="grey-textarea !bg-white !h-[164px]"
							value={formData.comment}
							onChange={e => handleChange("comment", e.target.value)}
						/>
					</div>

					<div className="max-w-[640px] w-full">
						<label className="font-semibold text-[18px] mb-4 block">3 reasons why the rating does not go higher</label>
						{formData.reasonsNotHigher?.map((item, index) => (
							<InputField
								key={index}
								className={`!bg-white grey-input-custom ${index < 2 ? "mb-4" : ""}`}
								value={item}
								onChange={(e) => handleArrayChange('reasonsNotHigher', index, e)}

							/>
						))}
					</div>

					<div className="max-w-[640px] w-full">
						<label className="font-semibold text-[18px] mb-4 block">3 reasons why the rating does not go lower</label>
						{formData.reasonsNotLower?.map((item, index) => (
							<InputField
								key={index}
								className={`!bg-white grey-input-custom ${index < 2 ? "mb-4" : ""}`}
								value={item}
								onChange={(e) => handleArrayChange('reasonsNotLower', index, e)}
							/>
						))}
					</div>

					<div className="flex justify-end">
						<PrimaryButton
							isLoading={isLoading}
							className="justify-center max-md:w-full lg:!h-[52px] lg:!text-lg px-[36px] font-bold text-white bg-sky-900 rounded-md max-md:px-5"
							onClick={handleSubmit}
						>
							Save
						</PrimaryButton>
					</div>

				</div>
			</div>
			<NextStepSectionSBM01 prev={handlePrev} next={handleNext} />
		</div>
	)
}

export default RatingOfTheJob