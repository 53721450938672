import React, {useEffect, useRef, useState} from "react";
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
const { CKEditor } = require( '@ckeditor/ckeditor5-react' );


// TODO нужно определить какой едитор будет использован и удалить лишний
const Editor = ({data, onChange, wrprClassNames = "mt-[16px] mb-[18px]", setInstanse, readOnly = false}) => {
    const [content, setContent] = useState(data || '');
    //
    // const handleChange = (event, editor) => {
    //     const data = editor.getData();
    //     setContent(data);
    // };
    //
    // return (
    //     <div>
    //         <CKEditor
    //             editor={ClassicEditor}
    //             data={content}
    //             onChange={handleChange}
    //         />
    //     </div>
    //
    // )
    const refdiv=useRef(null);
    const [rte, setRte] = useState(null)
    const [first, setFirst] = useState(null)
    useEffect(() => {
        if (rte && !first) {
            setFirst(true)
            rte && rte.setHTMLCode(data)
        }

    }, [data]);

    useEffect(() => {
        onChange && onChange(content)
    }, [content]);

  

    useEffect(() => {
        setTimeout(() => {
            let rte1 = new window.RichTextEditor(refdiv.current);
            setRte(rte1);
            
            setInstanse && setInstanse(rte1)
            rte1.setHTMLCode(data ); 
            rte1.attachEvent("change", function () {
                setContent(rte1.getHTMLCode())
            })
            rte1.setReadOnly(readOnly)

        }, 0)
    }, []);


    return (
        <div className={wrprClassNames}>
            <div ref={refdiv}></div>
        </div>
    );
}

export default Editor