import { useEffect, useState } from 'react';
import { Navigate, Outlet, useLocation, useParams } from 'react-router-dom';
import api from '../api';
import { useDispatch, useSelector } from 'react-redux';
import { fetchPageByPath } from '../redux/thunks/applicationThunks';
import { getPages } from '../redux/slices/applicationSlice';
import { getJobByIdFetch } from '../redux/thunks/jobsThunks';
import Breadcrumbs from '../components/base/Breadcrumbs';
import PageLoader from '../components/PageLoader';


const JobApplicationLayout = () => {
    const { id } = useParams();
    const dispatch = useDispatch();
    useEffect(() => {
		dispatch(getJobByIdFetch(id));
	}, [id, dispatch])
    const { job, isLoading, error } = useSelector(state => state.jobs);
    const { pathname } = useLocation();
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);
    const [formData, setFormData] = useState(() => {
        const answers = localStorage.getItem(`job-${id}-answers`);
        return answers ? JSON.parse(answers) : {
            most_recent_employment_history_answer: [{}],
        }
    });
    console.log(formData)
    return <div className='w-full flex flex-col'>
        {
            error ? 
                <p>{error}</p> :
            isLoading || !job ?
                <PageLoader /> :
                <Outlet context={[formData, setFormData]}/>
        }
    </div>
};

export default JobApplicationLayout