import React, { useState } from 'react';
import BreadCrumbWhite from "../components/BreadCrumbWhite";
import { Footer } from '../components/parts/Footer';
import Checkbox from '../components/base/Checkbox';
import classNames from 'classnames';
import SelectBox from '../components/base/SelectBox';
import MarcSprctorLinksGroup from '../components/parts/MarcSprctorLinksGroup';
import { Link } from 'react-router-dom';
import ModalGrop from '../components/parts/ModalGrop';
import {crumbsConfig, formPath, stepsPath} from "./SBM01_Form/config";

const reportCardData = [
	{
		title: "Clarification",
		percentage: "96%",
		status: "Completed",
		completed: true,
		borderColor: "border-sky-900",
		// linkTo: '/SBM01_10_18',
		linkTo: `${formPath}?step=${stepsPath["1/11"]}`
	},
	{
		title: "1st Interview",
		status: "A Score",
		progress: '56%',
		borderColor: "border-gray-200",
		// linkTo: '/SBM01_10_1',
		linkTo: `${formPath}?step=${stepsPath["2/11"]}`
	},
	{
		title: "Post Interview Survey",
		status: "A Score",
		progress: '20',
		borderColor: "border-gray-200",
		// linkTo: '/SBM01_10_2',
		linkTo: `${formPath}?step=${stepsPath["3/11"]}`
	},
	{
		title: "2nd Interview",
		status: "On-going",
		borderColor: "border-gray-200",
		// linkTo: '/SBM01_10_3',
		linkTo: `${formPath}?step=${stepsPath["4/11"]}`
	},
	{
		title: "Document Review",
		status: "Not Started",
		borderColor: "border-gray-200",
		// linkTo: '/SBM01_10_4',
		linkTo: `${formPath}?step=${stepsPath["5/11"]}`
	},
	{
		title: "Rating of the Job",
		status: "Not Started",
		borderColor: "border-gray-200",
		// linkTo: '/SBM01_10_16',
		linkTo: `${formPath}?step=${stepsPath["6/11"]}`
	},
	{
		title: "AI Evaluation",
		status: "Not Started",
		borderColor: "border-gray-200",
		// linkTo: '/SBM01_10_5',
		linkTo: `${formPath}?step=${stepsPath["7/11"]}`
	},
	{
		title: "Final Hiring Decisions",
		status: "Not Started",
		borderColor: "border-gray-200",
		// linkTo: '/SBM01_10_15',
		linkTo: `${formPath}?step=${stepsPath["8/11"]}`
	},
	{
		title: "Training Insights",
		status: "Not Started",
		borderColor: "border-gray-200",
		// linkTo: '/SBM01_10_6',
		linkTo: `${formPath}?step=${stepsPath["9/11"]}`
	},
];

const SBM01_10_14 = () => {
	return (
		<>
			<BreadCrumbWhite crumbs={[crumbsConfig.sub, crumbsConfig.jobs, crumbsConfig.applicant, crumbsConfig.spector, crumbsConfig.report]} />
			<div className='max-w-[1432px] flex-auto px-4 w-full pt-10 pb-20 lg:pt-[60px] lg:pb-[90px] mx-auto'>
				<p className="text-lg font-semibold text-sky-900">Sales Associate 11 / Candidates</p>
				<h2 className="mt-1.5 text-4xl font-bold leading-10 text-zinc-950">Marc Spector</h2>
				<ContactActions />
				<MarcSprctorLinksGroup activeLink={'report-card'} />
				<h2 className='my-9 lg:my-[60px] text-[28px] lg:text-[30px] font-bold text-[#333333]'>Report Card</h2>
				<div className='flex items-center gap-3 lg:gap-[18px] max-lg:mb-4'>
					<div className='aspect-square w-6 lg:w-[50px] flex items-center justify-center'>
						<Checkbox />
					</div>
					<p className='lg:text-2xl text-lg font-bold text-[#37B34A]'>John Doe</p>
					<p className='text-base lg:text-lg font-normal text-[#666666]'>Applied Date 3/28/2023</p>
				</div>
				<div className='flex items-center overflow-auto gap-[2px] border-b border-solid border-[#DEDED9]'>
					{reportCardData.map((item, index) => <div
						className='flex-1 flex flex-col items-center min-w-[150px]'
						key={index}
					// style={{
					// 	flex: '0 0 150px'
					// }}
					>
						<div className={classNames('w-full h-[90px] flex items-center flex-col gap-[6px] justify-center border-b-[5px] border-solid', item.completed ? 'border-[#003578]' : 'border-[#EBE9EA]')}>
							<Link to={item.linkTo} className='text-base font-bold text-[#37B34A] max-w-[150px] line-clamp-2 text-center'>{item.title}</Link>
							<p className='text-base font-normal text-[#777777]'>{item.percentage || 'Send'}</p>
						</div>
						<div className='h-[90px] flex items-center justify-center'>
							<p className='text-base font-normal text-[#777777]'>{item.status} {item.progress && <span className='text-[#37B34A]'>({item.progress})</span>}</p>
						</div>
					</div>)}
				</div>

			</div>
			<Footer hideCopyright={true} />
		</>
	)
}

function ContactActions() {
	return (
		<div className="mt-10 lg:mt-[36px] mb-4 flex gap-5 justify-between items-center p-[18px] bg-[#E7E9EA] max-lg:flex-col">
			<div className="flex flex-wrap gap-2">
				<ModalGrop theme='2' />
			</div>
			<div className="flex max-lg:w-full gap-4">
				<div className="flex flex-auto max-lg:flex-col gap-2 lg:gap-3">
					<div className="whitespace-nowrap items-center flex font-bold uppercase text-stone-500">
						Show
					</div>
					<SelectBox options={[ 
						{
							label: '10'
						},
						{
							label: '20'
						},
					]} svg={2} className='w-full lg:w-[74px] h-[50px]' />
				</div>
				<div className="flex flex-auto max-lg:flex-col gap-2 lg:gap-3">
					<div className="whitespace-nowrap items-center flex font-bold uppercase text-stone-500">
						Sort by
					</div>
					<SelectBox options={[ 
						{
							label: 'Date'
						},
						{
							label: 'Name'
						},
					]} svg={2} className='w-full lg:w-[92px] h-[50px]' />
				</div>
			</div>
		</div>
	);
}

export default SBM01_10_14