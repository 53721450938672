import React, {useEffect, useState} from "react";

export const TextQuestion = ({config, number, value, onChange, error, className = '', labelClassName = '',}) => {



    return <>
        <div className={`mt-6 ${className}`}>
            <label htmlFor={config.id} className={`mb-[16px] text-lg font-semibold leading-[30px] max-md:max-w-full max-md:text-[18px] max-md:leading-[24px] ${labelClassName} ${error ? 'text-red-600' : null}`}>
                {number ? number + '.':null} 
                {config.question}
                {config.isRequired && <span className="text-teal-500">*</span>}
            </label>
            <textarea name={config.id} value={value} onChange={(e) => onChange(e.target.value)} className="grey-textarea !p-[14px] lg:!h-[82px]"></textarea>
        </div>
    </>

}