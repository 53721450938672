const SelectField = ({ label, name, id, options, value, onChange, inputBg = "bg-slate-100", placeholder=null, parentClass ="mb-[18px] flex-col", labelClassName=""}) => (
    <div className={`flex gap-[16px] flex-1  ${parentClass}`}>
      {label && <label htmlFor={id} className={`text-[18px] font-bold text-zinc-800 leading-[18px] ${labelClassName}`}>{label}</label>}
      <select 
        id={id} 
        name={name} 
        className={`select-field-green rounded-md h-[54px] w-full px-[18px] text-[18px] ${inputBg}`}
        value={value}
        onChange={onChange}
      >
              {placeholder ? <option selected hidden>{placeholder}</option> : null}
              {options.map((option) => (
                  <option key={option.value} value={option.value}>{option.label}</option>
              ))}
      </select>
    </div>
);

export default SelectField;