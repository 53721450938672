import React, { useState, useEffect } from "react";
import BreadCrumbWhite from "../../components/BreadCrumbWhite";
import { JobDetailsNav } from "../../components/base/JobDetailsNav";
import { useNavigate, useParams } from 'react-router-dom';

import CurrentJobDetails from "./CurrentJobDetails";
import Description from "./Description";
import Pipelines from "./Pipelines";
import QualificationAttributes from "./QualificationAttributes";
import InterviewQuestions from "./InterviewQuestions";
import ApplicationForm from "./ApplicationForm";
import DispositionStatus from "./DispositionStatus";
import ContactForm from '../../components/parts/ContactForm';
import PageLoader from "../../components/PageLoader";
import { toast } from 'react-toastify';
import api from "../../api";
import Confirmation from "../../components/Confirmation";

const JobEdit = () => {
    const navigate = useNavigate();
    const { id, step } = useParams();
    // console.log("id:", id, "step:", step);

    const currentStepComponents = {
        "current-job-details": CurrentJobDetails,
        "description": Description,
        "pipelines": Pipelines,
        "qualification-attributes": QualificationAttributes,
        "interview-questions": InterviewQuestions,
        "application-form": ApplicationForm,
        "disposition-status": DispositionStatus,
    }

    const CurrentStepComponent = currentStepComponents[step];

    const breadcrumbsInit = [
        ["Current Job Details", `/job-edit/${id}/current-job-details`],
        ["Description", `/job-edit/${id}/description`],
        ["Pipelines", `/job-edit/${id}/pipelines`],
        ["Qualification Attributes", `/job-edit/${id}/qualification-attributes`],
        ["Interview Questions", `/job-edit/${id}/interview-questions`],
        ["Application Form", `/job-edit/${id}/application-form`],
        ["Disposition Status", `/job-edit/${id}/disposition-status`],
    ];

    const breadcrumbs = {
        "current-job-details": [breadcrumbsInit[0]],
        "description": breadcrumbsInit.slice(0, 2),
        "pipelines": breadcrumbsInit.slice(0, 3),
        "qualification-attributes": breadcrumbsInit.slice(0, 4),
        "interview-questions": breadcrumbsInit.slice(0, 5),
        "application-form": breadcrumbsInit.slice(0, 6),
        "disposition-status": breadcrumbsInit.slice(0, 7),
    }

    const [payload, setPayload] = useState(null);
    console.log('payload:', payload);

    const [updatedPayload, setUpdatedPayload] = useState({});
    console.log('updatedPayload:', updatedPayload);

    const [industriesList, setIndustriesList] = useState([]);

    const [contactFormOpen, setContactFormOpen] = useState(false);
    const [contactFormOpenSended, setContactFormOpenSended] = useState(false);

    const [qaPresetAttributesSelected, setQaPresetAttributesSelected] = useState(null);


    const handleChange = (value) => {
        setPayload((prev)=>{
            Object.entries(prev).map((item)=>{
                if(item[1] !== value[item[0]]){
                    if(item[0] !== "applicationForm" && item[0] !== "dispositionStatus"){
                        setUpdatedPayload((prev)=>{
                            return {
                                ...prev,
                                [item[0]]: value[item[0]]
                            }
                        });
                    } else if(JSON.stringify(item[1]) !== JSON.stringify(value[item[0]])) {
                        setUpdatedPayload((prev)=>{
                            return {
                                ...prev,
                                [item[0]]: value[item[0]]
                            }
                        });
                    }
                }
            });

            return value;
        });
    };

    const jobGetData = async() => {
        let res = await api.jobs.getJobById(id);

        setPayload({
            ...res?.data,
            attachments: [],
            files_map: {},
        });

        if(res?.data.industrieId){
            let qa_res = await api.jobs.getQaPresetAttributesListByIndustryId(res?.data.industrieId);

            if(qa_res?.data && res?.data?.qaPresetId && qa_res?.data.find(item => item?.id === res?.data?.qaPresetId)){
                setQaPresetAttributesSelected(qa_res?.data.find(item => item?.id === res?.data?.qaPresetId));
            }
        }
    }

    const getIndustriesList = async(id) => {
        let res = await api.jobs.getIndustriesList();

        setIndustriesList(res.data)
    }

    const save = async(restPayload = null) => {
        setPayload({
            ...payload,
            applicationForm: {
                ...payload.applicationForm,
                please_review_the_following_terms_disclosures_and_consent_files_1: null,
                please_review_the_following_terms_disclosures_and_consent_files_2: null,
                please_review_the_following_terms_disclosures_and_consent_files_3: null,
            }
        });

        let formDataPayload = new FormData();

        Object.entries(updatedPayload).forEach(item => {
            if(["applicationForm", "dispositionStatus", "files_map", "mainInfo"].includes(item[0])){
                formDataPayload.append(item[0], JSON.stringify(item[1]));
            } else if(item[0] === "attachments"){
                for (let index = 0; index < payload.attachments.length; index++) {
                    formDataPayload.append("attachments", payload.attachments[index], payload.attachments[index].name);
                }
            } else {
                formDataPayload.append(item[0], item[1]);
            }
        });

        let res = await api.jobs.changeJob({
            jobId: payload.id,
            rest: restPayload ? restPayload : formDataPayload
        });

        if(res?.success){
            toast.success(res?.message || restPayload?.status === "archived" ? "Job archived successfully" : restPayload?.status === "active" ? "Job active successfully" : "Job updated successfully");
            navigate("/jobs-setup");
        } else {
            toast.error(res?.message || "Something went wrong");
        }
    }

    const statusToggle = async() => {
        await save({ status: payload?.status === "archived" ? "active" : "archived" });
    }

    useEffect(() => {
        jobGetData();
        getIndustriesList();
    }, []);
    
    useEffect(() => {
        if(contactFormOpenSended){
            setTimeout(() => {
                setContactFormOpenSended(false);
                setContactFormOpen(false);
            }, 3000);
        }
    }, [contactFormOpenSended]);

    return (
        <>
            <BreadCrumbWhite
                crumbs={[
                    'Subscriber Dashboard', ['Job Setup', '/jobs-setup'], ...breadcrumbs[step],
                    ...(contactFormOpen && !contactFormOpenSended ? ['Contact Us'] : contactFormOpenSended ? ['Contact Us', 'Thank You'] : [])
                ]}
            />

            <div className='flex-auto'>
                <div className='flex flex-col gap-[60px] max-md:gap-[40px] max-w-[1432px] px-4 w-full pt-14 max-md:pt-[40px] pb-24 max-md:pb-[80px] mx-auto' onClick={(e)=>{
                    if(e.target.classList.contains("max-w-[1432px]")){
                        setContactFormOpen(false);
                    }
                }}>
                    <JobDetailsNav />

                    { !industriesList?.length || !payload ? 
                        <PageLoader /> : 
                        !contactFormOpen ?
                        <CurrentStepComponent
                            payload={payload}
                            setPayload={handleChange}
                            setContactFormOpen={setContactFormOpen}
                            industriesList={industriesList}
                            qaPresetAttributesSelected={qaPresetAttributesSelected}
                            setQaPresetAttributesSelected={setQaPresetAttributesSelected}
                            save={save}
                            statusToggle={statusToggle}
                        /> : contactFormOpenSended ?
                        <Confirmation /> :
                        <ContactForm
                            companyId={payload?.companyId}
                            setContactFormOpen={setContactFormOpen}
                            setContactFormOpenSended={setContactFormOpenSended}
                            areas={breadcrumbs[step].pop()[0]}
                        />
                    }
                </div>
            </div>
        </>
    )
}

export default JobEdit