import React, { useEffect } from 'react';
import { NavLink, useLocation, useParams } from 'react-router-dom';

export function JobDetailsNav() {
    const { search } = useLocation();
    const { id } = useParams();

    const navItems = [
        { label: "Current Job Details", route: `/job-edit/${id}/current-job-details` },
        { label: "Description", route: `/job-edit/${id}/description` },
        { label: "Pipelines", route: `/job-edit/${id}/pipelines` },
        { label: "Qualification Attributes", route: `/job-edit/${id}/qualification-attributes` },
        { label: "Interview Questions", route: `/job-edit/${id}/interview-questions` },
        { label: "Application Form", route: `/job-edit/${id}/application-form` },
        { label: "Disposition Status", route: `/job-edit/${id}/disposition-status` },
    ];

    // const navItems = [
    //     { label: "Current Job Details", route: "/SBM02_7_1" },
    //     { label: "Description", route: "/SBM02_7_7" },
    //     { label: "Pipelines", route: "/SBM02_7_2" },
    //     { label: "Qualification Attributes", route: "/SBM02_7_3" },
    //     { label: "Interview Questions", route: "/SBM02_7_4" },
    //     { label: "Application Form", route: "/SBM02_7_5" },
    //     { label: "Disposition Status", route: "/SBM02_7_6" },
    // ];

    useEffect(() => {
        if(document.body.offsetWidth > 1200) {
            return
        }
        const currentEL = document.querySelector('[aria-current="page"]')
        if(currentEL) {
            const offset = (document.body.offsetWidth - currentEL.clientWidth) / 2
            const scrollLeft = currentEL.offsetLeft - offset;
            currentEL.closest(".scrollable").scrollLeft = scrollLeft;
        }
    }, [search])

    return (
        <nav className="flex max-lg:overflow-x-auto scrollable overflow-y-hidden whitespace-nowrap">
            {navItems.map((item, index) => (
                <NavItem key={index} route={item.route} label={item.label} query={search} />
            ))}
        </nav>
    );
}

function NavItem({ label, route, query }) {
    const baseClasses = "flex justify-center text-base font-semibold min-w-[190px] px-7 h-[68px] hover:bg-green-500 hover:text-white items-center border-l flex-1 border-solid bg-[#F7F7F7] border-zinc-300";
    const activeClasses = "text-white bg-green-500";
    // const activeClasses = query === route ? "text-white bg-green-500" : "bg-neutral-100";

    return (
        <NavLink
            to={route}
            className={({ isActive }) => isActive ? activeClasses + " " + baseClasses : baseClasses}
        >
            {label}
        </NavLink>
    );
}
