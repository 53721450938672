import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Outlet, useParams } from "react-router-dom";
import BreadCrumbWhite from "../../../components/BreadCrumbWhite";
import { Footer } from "../../../components/parts/Footer";
import ModalGrop from "../../../components/parts/ModalGrop";
import { getSubscriberRole } from "../../../redux/slices/applicationSlice";
import { getApplicationByIdFetch, getJobByIdFetch } from "../../../redux/thunks/jobsThunks";
import { HiringProcessSBM01 } from "../base/HiringProcessSBM01";
import { TabsSBM01 } from "../base/TabsSBM01";
import PageLoader from "../../../components/PageLoader";
import { baseCrumbs } from "../config";

export const ReportCardLayoutNew = () => {
    const subscriberRole = useSelector(getSubscriberRole);
    const { job, isLoading, application } = useSelector(state => state.jobs)
    const { jobId, applicationId } = useParams();
    const dispatch = useDispatch()
    const [activeStep, setActiveStep] = useState(1)

    useEffect(() => {
        if (jobId && applicationId) {
            Promise.all([
                dispatch(getJobByIdFetch(jobId)),
                dispatch(getApplicationByIdFetch({ applicationId, jobId }))
            ]);
        }
    }, [jobId, applicationId, dispatch]);


    if (isLoading) return <PageLoader />

    const steps = [
        ["Clarification", `/SBM01_10_18/${jobId}/${applicationId}`],
        ["1st Interview", `/SBM01_10_1/${jobId}/${applicationId}`],
        ["Post Interview Survey", `/SBM01_10_2/${jobId}/${applicationId}`],
        ["2nd Interview", `/SBM01_10_3/${jobId}/${applicationId}`],
        ["Document Review", `/SBM01_10_4/${jobId}/${applicationId}`],
        ["Rating of the Job", `/SBM01_10_16/${jobId}/${applicationId}`],
        ["AI Evaluation", `/SBM01_10_5/${jobId}/${applicationId}`],
        ["Final Hiring Decisions", `/SBM01_10_5_5/${jobId}/${applicationId}`],
        ["Training Insights", `/SBM01_10_6/${jobId}/${applicationId}`],
        ["Offer Meeting", `/SBM01_10_7/${jobId}/${applicationId}`],
        ["Training Milestones", `/SBM01_10_8/${jobId}/${applicationId}`]
    ];
    const crumbs = [
        ...baseCrumbs({ jobId, applicationId, fullName: application?.fullName }),
        ["Report Card", `/SBM01_10_14(2)/${jobId}/${applicationId}`],
        activeStep ? steps[activeStep - 1] : steps[0]
    ]



    return (
        <>
            <BreadCrumbWhite crumbs={crumbs} />
            <div className='flex-auto'>
                <div
                    className='flex flex-col gap-11 max-w-[1432px] px-4 w-full pt-14 pb-24 mx-auto max-md:pt-[40px] max-md:pb-[80px]'>
                    <header
                        className="flex gap-5 justify-between w-full whitespace-nowrap max-md:flex-wrap max-md:max-w-full max-md:gap-[40px]">
                        <div>
                            {
                                !isLoading ?
                                    <p className="text-lg font-semibold text-sky-900">{job?.jobTitle} / Candidates</p>
                                    :
                                    null
                            }
                            <h2 className="mt-1.5 text-4xl font-bold leading-10 text-zinc-950">{application?.fullName}</h2>
                        </div>
                        <div className="flex flex-wrap gap-2 my-auto">
                            <ModalGrop tagModal={true} selectedApplicant={application} />
                        </div>
                    </header>

                    {
                        subscriberRole !== "interviewer" ?
                            <TabsSBM01 activeLink={"report-card"} />
                            :
                            null
                    }

                    <HiringProcessSBM01 activeTab={activeStep} />

                    {
                        job && application && jobId && applicationId ?
                            <Outlet context={{ job, application, jobId, applicationId, setActiveStep }} />
                            : null
                    }
                </div>
            </div>
            <Footer hideCopyright={true} />
        </>
    )
}