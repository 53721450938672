import { useEffect } from 'react';
import { useNavigate, useOutletContext } from 'react-router-dom';
import { NextStepSectionSBM01 } from '../base/NextStepSectionSBM01';


const AIRecommendation = () => {
	const navigate = useNavigate()
	const { job, application, jobId, applicationId, setActiveStep } = useOutletContext()
	useEffect(() => {
		setActiveStep(7)
	}, [])

	const handlePrev = () => {
		navigate(`/SBM01_10_16/${jobId}/${applicationId}`)
	}
	const handleNext = () => {
		navigate(`/SBM01_10_5_5/${jobId}/${applicationId}`)
	}

	return (
		<div className='flex-auto'>
			<div className='flex flex-col gap-11 max-w-[1440px] px-5 w-full pt-10 pb-20 lg:pt-14 lg:pb-24 mx-auto'>
				<section className="pb-11 lg:pb-16 border-b border-solid border-zinc-300">
					<StandardsComponent />
				</section>
				<PerformanceReview />
			</div>
			<NextStepSectionSBM01 prev={handlePrev} next={handleNext} />
		</div>
	)
}

const standards = [
	{ id: 1, level: "High Standards", imgSrc: "https://cdn.builder.io/api/v1/image/assets/TEMP/96fa0a208b800e04a9747eaac11c3a98e3a912adfb9728bfb25e2142b74ebb23?apiKey=d7b3afa51f454b40b57bf7bd36e9abaf&", alt: "High standard icon" },
	{ id: 2, level: "Medium Standards", imgSrc: "https://cdn.builder.io/api/v1/image/assets/TEMP/96fa0a208b800e04a9747eaac11c3a98e3a912adfb9728bfb25e2142b74ebb23?apiKey=d7b3afa51f454b40b57bf7bd36e9abaf&", alt: "Medium standard icon" },
	{ id: 3, level: "Average Standards", imgSrc: "https://cdn.builder.io/api/v1/image/assets/TEMP/96fa0a208b800e04a9747eaac11c3a98e3a912adfb9728bfb25e2142b74ebb23?apiKey=d7b3afa51f454b40b57bf7bd36e9abaf&", alt: "Average standard icon" },
];

const StandardCard = ({ level, imgSrc, alt }) => (
	<section className="flex flex-col w-full lg:w-[33%]">
		<div className="flex flex-col grow justify-center font-bold md:whitespace-nowrap text-zinc-800">
			<h2 className="text-4xl leading-8">{level}:</h2>
			<div className="flex gap-2.5 mt-4 lg:mt-7 text-2xl leading-7">
				<img loading="lazy" src={imgSrc} alt={alt} className="shrink-0 w-8 aspect-square" />
				<div className="flex-auto">Accept</div>
			</div>
		</div>
	</section>
);

const StandardsComponent = () => (
	<div className="flex gap-[60px] max-lg:flex-col">
		{standards.map((standard) => (
			<StandardCard key={standard.id} level={standard.level} imgSrc={standard.imgSrc} alt={standard.alt} />
		))}
	</div>
);

const dataStrengths = [
	{ id: 21, name: "client_service_empathy", percentage: 43 },
	{ id: 26, name: "team_or_solo", percentage: 33 },
	{ id: 15, name: "job_outlook", percentage: 28 },
	{ id: 5, name: "overall_for_the_3rd_in_person_interview", percentage: 24 },
	{ id: 20, name: "feedbacks", percentage: 23 },
];

const dataWeaknesses = [
	{ id: 21, name: "ftm_score", percentage: -109 },
	{ id: 26, name: "safety", percentage: -42 },
	{ id: 15, name: "snag_fit_survey_tough_minded", percentage: -28 },
	{ id: 5, name: "trustworthiness_integrity", percentage: -19 },
	{ id: 20, name: "self_evaluation_score_for_the_job", percentage: -10 },
];

const PerformanceReview = () => (
	<div className="w-full">
		<h2 className="lg:text-[40px] text-4xl font-bold text-[#333333]">Top Variables</h2>
		<p className="lg:text-[20px] text-lg text-zinc-800 my-4">The variables driving Christine Argy/s score are:</p>
		<div className="flex flex-wrap md:flex-nowrap gap-[54px] lg:mt-[54px] mt-11">
			<div className="w-full overflow-auto">
				<h2 className="lg:text-[30px] text-2xl font-bold  text-[#333333] mb-4">Top Strengths</h2>
				<table className="w-full text-left border-collapse text-[#666666] font-semibold">
					<thead>
						<tr className="bg-neutral-100 whitespace-nowrap">
							<th className="px-[18px] h-[46px] border"></th>
							<th className="px-[18px] h-[46px] border font-semibold">term</th>
							<th className="px-[18px] h-[46px] border font-semibold text-center">percent difference</th>
						</tr>
					</thead>
					<tbody>
						{dataStrengths.map(({ id, name, percentage }) => (
							<tr key={id}>
								<td className="px-[18px] h-[56px] border text-center">{id}</td>
								<td className="px-[18px] h-[56px] border">{name}</td>
								<td className={`px-[18px] h-[56px] border font-semibold text-white text-center ${percentage > 0 ? 'bg-[#37B34A]' : 'bg-[#FF0002]'}`}>{`${percentage > 0 ? '+' : ''}${percentage}%`}</td>
							</tr>
						))}
					</tbody>
				</table>
			</div>
			<div className="w-full overflow-auto">
				<h2 className="lg:text-[30px] text-2xl font-bold  text-[#333333] mb-4">Top Strengths</h2>
				<table className="w-full text-left border-collapse text-[#666666] font-semibold">
					<thead>
						<tr className="bg-neutral-100 whitespace-nowrap">
							<th className="px-[18px] h-[46px] border"></th>
							<th className="px-[18px] h-[46px] border font-semibold">term</th>
							<th className="px-[18px] h-[46px] border font-semibold text-center">percent difference</th>
						</tr>
					</thead>
					<tbody>
						{dataWeaknesses.map(({ id, name, percentage }) => (
							<tr key={id}>
								<td className="px-[18px] h-[56px] border text-center">{id}</td>
								<td className="px-[18px] h-[56px] border">{name}</td>
								<td className={`px-[18px] h-[56px] border font-semibold text-white text-center ${percentage > 0 ? 'bg-[#37B34A]' : 'bg-[#FF0002]'}`}>{`${percentage > 0 ? '+' : ''}${percentage}%`}</td>
							</tr>
						))}
					</tbody>
				</table>
			</div>
		</div>
	</div>
);

export default AIRecommendation