import React, { useCallback, useState } from "react"
import logo from "../assets/img/logo.png"
import SelectBox from "../components/base/SelectBox"
import FileInputField from "../components/base/FileInputField"
import { useLocation, useNavigate, useOutletContext, useParams } from "react-router-dom";
import Breadcrumbs from "../components/base/Breadcrumbs";
import { RegisterApplicantSteps, initialAnswers } from "../components/parts/RegisterApplicantSteps";
import { useSelector } from "react-redux";
import api from "../api";
import { toast } from "react-toastify";
import PageLoader from "../components/PageLoader";

const AP_44 = () => {
    const { id } = useParams();
    const { job: {applicationForm} } = useSelector(state => state.jobs);
    console.log(applicationForm)
    const [formData, setFormData] = useOutletContext();
    const handleChange = useCallback((field, value) => {
        setFormData(prev => ({ ...prev, [field]: value }))
    }, [setFormData]) 
    const navigate = useNavigate();
    const { pathname } = useLocation();
    const jobLink = pathname.split('/you-must-submit-your-resume-here')[0]
    const breadcrumbItems = [
        {
            name: 'Job',
            to: jobLink,
            isCurrent: false
        },
        {
            name: 'You Must Submit Your Resume Here',
            isCurrent: true
        },
    ]
    const [resumeFile, setResumeFile] = useState(null);
    console.log(resumeFile)
    const [personalInfo, setPersonalInfo] = useState({
        email: '',
        phone: '',
        fullName: ''
    })
    const next = (url = '') => {
        navigate(url)
    }

    const setSignatureFile = (imgBase64) => {
        function DataURIToBlob(dataURI) {
            const splitDataURI = dataURI.split(',')
            const byteString = splitDataURI[0].indexOf('base64') >= 0 ? atob(splitDataURI[1]) : decodeURI(splitDataURI[1])
            const mimeString = splitDataURI[0].split(':')[1].split(';')[0]
    
            const ia = new Uint8Array(byteString.length)
            for (let i = 0; i < byteString.length; i++)
                ia[i] = byteString.charCodeAt(i)
    
            return new Blob([ia], { type: mimeString })
        }

        const file = DataURIToBlob(imgBase64);

        return file;
    }
    const [isLoading, setIsLoading] = useState(false);

    const handleSubmit = async() => {
        if(!formData.you_must_copy_and_paste_your_resume_here_please_copy_and_paste_answer && !resumeFile) {
            toast.error("Resume is requiered");
            return;
        }
        setIsLoading(true);
        // if(!formData.you_must_copy_and_paste_your_resume_here_linkedin_profile_url_answer) {
        //     toast.error("LinkedIn Profile URL is requiered");
        //     return
        // }
        // if(!personalInfo.fullName) {
        //     toast.error("Full Name is requiered");
        //     return
        // }
        // if(!personalInfo.email) {
        //     toast.error("Email is requiered");
        //     return
        // }
        // if(!personalInfo.phone) {
        //     toast.error("Phone is requiered");
        //     return
        // }

        var data = new FormData();        
        
        data.append('files_map', JSON.stringify({
            ...resumeFile?.name && {you_must_copy_and_paste_your_resume_here_alternatively_you_can_also_attached_answer: resumeFile.name},
            eSignature: `${formData.fullName}-signature.png`
        }));

        const blobFile = setSignatureFile(formData.eSignature);

        resumeFile?.name && data.append('attachments', resumeFile, resumeFile.name);
        data.append('attachments', blobFile, `${formData.fullName}-signature.png`);

        data.append('email', formData.email);
        data.append('fullName', formData.fullName)
        data.append('phone', formData.phone)
        data.append('source', 'Carrer Site');

        let applicationFormFormData = {...formData};

        applicationFormFormData.eSignature = "";

        data.append('applicationForm', JSON.stringify(applicationFormFormData));

        const res = await api.jobApplications.createJobApplication(id,
            // {
            //     applicationForm: formData,
            //     files_map: {
            //         you_must_copy_and_paste_your_resume_here_alternatively_you_can_also_attached_answer: resumeFile.name
            //     },
            //     attachments: [resumeFile],
            //     ...personalInfo
            // }
            data
        );
        setIsLoading(false)
        if(res.success) {
            // localStorage.removeItem(`job-${id}-answers`)
            localStorage.setItem(`job-${id}-answers`, JSON.stringify(formData));
			localStorage.setItem(`job-${id}-steps`, JSON.stringify(initialAnswers.map(answer => ({...answer, isDone: answer.number <= 6 ? true : false}))));
            navigate(`${jobLink}/the-next-step?applicationId=${res.data.id}`)
        }
        console.log(res)
    }

    return (<>
        <Breadcrumbs links={breadcrumbItems} colorStyle="secondary" />
        <main className="flex gap-[60px] max-md:flex-col max-md:gap-0 pt-[28px] pb-[90px]">
            <RegisterApplicantSteps step={6} />
            <section className="flex flex-col max-md:ml-0 max-md:w-full">
                <div className="flex flex-col  text-zinc-800 max-md:mt-10 max-md:max-w-full">
                    {applicationForm.you_must_copy_and_paste_your_resume_here_active && <>
                        <h1 className="text-[28px] md:text-3xl font-bold lg:leading-10 leading-[36px] max-md:max-w-full">
                            {applicationForm.you_must_copy_and_paste_your_resume_here_title}
                        </h1>
                        <p className="mt-6 text-lg font-light leading-[30px] max-md:max-w-full">
                            {applicationForm.you_must_copy_and_paste_your_resume_here_text}
                        </p>
                        <div className="flex flex-col pt-11 mt-11 border-t border-solid border-stone-300 max-md:mt-10 max-md:max-w-full">
                            <h2 className="text-[22px] md:text-3xl font-bold leading-7 lg:leading-10 max-md:max-w-full">
                                {applicationForm.you_must_copy_and_paste_your_resume_here_please_copy_and_paste}
                            </h2>
                            <textarea 
                                className="grey-textarea mt-[24px]"
                                value={formData.you_must_copy_and_paste_your_resume_here_please_copy_and_paste_answer}
                                onChange={(e) => handleChange('you_must_copy_and_paste_your_resume_here_please_copy_and_paste_answer', e.target.value)}
                            />
                            <h2 className="text-[18px] md:text-lg font-bold max-md:max-w-full mt-[24px] md:mt-[38px] mb-[16px]">
                                {applicationForm.you_must_copy_and_paste_your_resume_here_alternatively_you_can_also_attached}
                            </h2>
                            <FileInputField 
                                boxClassName="mb-[22px]"
                                acceptProp={{
                                    'application/pdf': ['.pdf']
                                }}
                                fileProp={resumeFile}
                                handleChange={(file) => {
                                    handleChange('you_must_copy_and_paste_your_resume_here_alternatively_you_can_also_attached_answer', file?.name)
                                    setResumeFile(file);
                                }}
                            />
                            <div>
                                <p className="mb-[16px] text-[18px] md:text-lg font-semibold leading-[30px] max-md:max-w-full">
                                    {applicationForm.you_must_copy_and_paste_your_resume_here_linkedin_profile_url}
                                </p>
                                <input 
                                    type="text"
                                    className="grey-input-custom" 
                                    value={formData.you_must_copy_and_paste_your_resume_here_linkedin_profile_url_answer}
                                    onChange={(e) => handleChange('you_must_copy_and_paste_your_resume_here_linkedin_profile_url_answer', e.target.value)}
                                />
                            </div>
                        </div>
                    </>}
                    


                    <footer className="flex flex-col justify-center mt-11 text-lg font-bold text-center text-white whitespace-nowrap border-t border-solid border-stone-300 max-md:mt-10 max-md:max-w-full">
                        <div className="flex gap-5 justify-between pt-10 w-full">
                            <button
                                className="flex items-center h-[52px] justify-center px-12 py-5 bg-gray-500 rounded-md max-lg:flex-auto max-lg:px-2"
                                onClick={(e) => { e.preventDefault(); navigate(`${jobLink}/critical-requirement`); }}
                            >Previous</button>
                            <button
                                className="flex items-center h-[52px] justify-center px-9 py-5 bg-teal-500 rounded-md max-lg:flex-auto max-lg:px-2"
                                onClick={handleSubmit}
                            >
                                {isLoading ? <PageLoader className='h-[40px]' /> :  'Save & Continue'}
                               
                                
                            </button>
                        </div>
                    </footer>
                </div>
            </section>
        </main>
    </>)
}


export default AP_44