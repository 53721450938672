const offerTemplatesBase = 'api/offer-templates';

export const offerTemplates = (instance) => ({
    async create(jobApplicationId, payload) {
        const { data } = await instance.post(`${offerTemplatesBase}/${jobApplicationId}`, payload);
        return data;
    },
    async getAll(companyId) {
        const { data } = await instance.get(`${offerTemplatesBase}/${companyId}`);
        return data;
    },
    async update(jobApplicationId, payload) {
        try {
            const { data } = await instance.put(`${offerTemplatesBase}/${jobApplicationId}`, payload);
            return data;
        } catch(e) {
            return e.response.data;
        }
    },
    async delete(jobApplicationId, referenceId) {
        try {
            const { data } = await instance.delete(`${offerTemplatesBase}/${jobApplicationId}/${referenceId}`);
            return data;
        } catch(e) {
            return e.response.data;
        }
    },
});

