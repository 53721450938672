import { useEffect } from 'react';
import { useNavigate, useOutletContext } from 'react-router-dom';
import { RangeQuestion } from "../../AP_01_Form/parts/RangeQuestion";
import { TextQuestion } from "../../AP_01_Form/parts/TextQuestion";
import { VariantsQuestion } from "../../AP_01_Form/parts/VariantsQuestion";
import { YesNoQuestion } from "../../AP_01_Form/parts/YesNoQuestion";
import { NextStepSectionSBM01 } from '../base/NextStepSectionSBM01';

const TotalScore = ({ title, score, isGreen }) => (
	<div className="flex gap-5 mt-6 max-w-full text-lg whitespace-nowrap w-[400px] text-center">
		<div className="grow flex justify-center items-center px-16 lg:h-[54px] h-[52px] font-semibold bg-neutral-100 w-fit text-[#333333]">
			{title}
		</div>
		<div
			className="flex gap-2 justify-center items-center px-5 py-1 min-w-[96px] lg:h-[54px] h-[52px] bg-white border border-solid border-zinc-300"
		>
			{isGreen && (
				<div className="shrink-0 my-auto w-3 h-3 bg-green-500 rounded-[360px]" />
			)}
			<div>{score}%</div>
		</div>
	</div>
);
const InterviewSection = ({ questions }) => {

	return (
		<div className="flex flex-col items-stretch">
			<div className="self-stretch w-full text-3xl font-bold leading-8 text-zinc-800 max-md:max-w-full">
				Post Interview Survey
			</div>
			<div className="self-stretch mt-9 max-md:mt-[24px] w-full border border-solid bg-neutral-200 border-neutral-200 min-h-[1px] max-md:max-w-full" />
			<TotalScore title="Total Score" score={90} />
			<div className="mt-[32px]">
				{questions}
			</div>
		</div>
	);
};

const PostFirstInterviewSurvey = () => {
	const navigate = useNavigate()
	const { job, application, jobId, applicationId, setActiveStep } = useOutletContext()
	useEffect(() => {
		setActiveStep(3)
	}, [])

	const questionsData = job && application ? job.applicationForm.critical_requirements_of_the_job_questions.map((item, index) => ({ ...item, answerValue: application.applicationForm.critical_requirements_of_the_job_answers[index] })).filter(item => item.questionCode.includes('FS')) : [];

	const QuestionTypes = {
		'yes-no': YesNoQuestion,
		'text': TextQuestion,
		'range': RangeQuestion,
		'variants': VariantsQuestion
	}

	const handleNext = () => {
		navigate(`/SBM01_10_3/${jobId}/${applicationId}`)
	}
	const handlePrev = () => {
		navigate(`/SBM01_10_1/${jobId}/${applicationId}`)
	}
	return (
		<div className='flex-auto'>
			<div className='flex flex-col gap-11 max-w-[1432px] px-4 w-full pt-10 pb-20 lg:pt-14 lg:pb-24 mx-auto'>
				<InterviewSection
					questions={<div className="flex flex-col gap-[32px] self-stretch max-w-[720px]">
						{questionsData.map((item, index) => {
							const Question = QuestionTypes[item.answerType];
							if (!Question) return null
							return <Question
								key={item.id}
								error={null}
								config={item}
								number={index + 1}
								value={item.answerValue || null}
								className="mt-[0px]"
								labelClassName="!text-[16px] !leading-[20px]"
							/>
						})}
					</div>}
				/>
			</div>
			<NextStepSectionSBM01 prev={handlePrev} next={handleNext} />
		</div>
	)
}

export default PostFirstInterviewSurvey
