import React from "react"
import { Link, useLocation, useNavigate, useParams } from "react-router-dom"
import Breadcrumbs from "../components/base/Breadcrumbs";
import { RegisterApplicantSteps, initialAnswers } from "../components/parts/RegisterApplicantSteps";
import { useSelector } from "react-redux";

const AP_15 = () => {
    const navigate = useNavigate();
    const { job: {applicationForm} } = useSelector(state => state.jobs)
    const { pathname } = useLocation();
    const jobLink = pathname.split('/it-takes-x-minutes')[0]
    const breadcrumbItems = [
        {
            name: 'Job',
            to: jobLink,
            isCurrent: false
        },
        {
            name: 'It Takes X Minutes',
            to: pathname,
            isCurrent: true
        },
    ]
    const { id } = useParams();
    const handleSubmit = (e) => {
        e.preventDefault()
        const stepsDone = [1]
        localStorage.setItem(`job-${id}-steps`, JSON.stringify(initialAnswers.map(answer => ({...answer, isDone: stepsDone.includes(answer.number) ? true : false}))));
        navigate(`${jobLink}/consent`)
    }

    return (<>
        <Breadcrumbs links={breadcrumbItems} colorStyle="secondary" />

        <main className="flex gap-[60px] max-md:flex-col max-md:gap-0 pt-[28px] pb-[80px] lg:pb-[90px]">
            <RegisterApplicantSteps step={1} />
            <section className="flex flex-col ml-5 w-[65%] max-md:ml-0 max-md:w-full">
                <div className="flex flex-col  text-zinc-800 max-md:mt-[80px] max-md:max-w-full">
                    {applicationForm.it_takes_15_minutes_active && <>
                        <h1 className="text-3xl font-bold leading-10 max-md:max-w-full max-md:text-[28px]">
                            {applicationForm.it_takes_15_minutes_title}
                        </h1>
                        <p className="mt-6 text-lg font-light leading-[30px] max-md:max-w-full">
                            {applicationForm.it_takes_15_minutes_text}
                        </p>
                    </>}
                    {applicationForm.what_you_need_active &&
                        <div className="flex flex-col pt-11 mt-11 border-t border-solid border-stone-300 max-md:mt-10 max-md:max-w-full">
                            <h2 className="text-3xl mb-6 font-bold leading-10 max-md:max-w-full max-md:text-[28px]">
                                {applicationForm.what_you_need_title}
                            </h2>
                            {
                                applicationForm.what_you_need_text.map((requirement, index) => (
                                    <p key={index} className="text-lg font-light leading-[30px] max-md:max-w-full">
                                        {index + 1}. {requirement}
                                    </p>
                                ))
                            }
                        </div>
                    }
                    {applicationForm.how_long_it_takes_active && 
                        <div className="flex flex-col pt-11 mt-11 border-t border-solid border-stone-300 max-md:mt-10 max-md:max-w-full">
                            <h2 className="text-3xl font-bold leading-10 max-md:max-w-full max-md:text-[28px]">
                                {applicationForm.how_long_it_takes_title}
                            </h2>
                            <p className="mt-6 text-lg font-light leading-[30px] max-md:max-w-full">
                                {applicationForm.how_long_it_takes_text}
                            </p>
                        </div>
                    }
                    <footer className="flex flex-col justify-center mt-11 text-lg font-bold text-center text-white whitespace-nowrap border-t border-solid border-stone-300 max-md:mt-10 max-md:max-w-full">
                        <div className="flex gap-5 justify-between pt-10 max-md:flex-wrap max-md:max-w-full">
                            <Link to={jobLink} className="flex items-center h-[52px] justify-center px-12 py-5 bg-gray-500 rounded-md max-md:px-5 max-md:flex-1">
                                Previous
                            </Link>
                            <button
                                className="flex items-center h-[52px] justify-center px-9 py-5 bg-teal-500 rounded-md max-md:px-5 max-md:flex-1"
                                onClick={handleSubmit}
                            >
                                Save & Continue
                            </button>
                        </div>
                    </footer>
                </div>
            </section>
        </main>
    </>)
}


export default AP_15