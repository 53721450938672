import React, { useEffect, useState } from 'react';
import BreadCrumbWhite from "../components/BreadCrumbWhite";
import { Footer } from '../components/parts/Footer';
import Checkbox from '../components/base/Checkbox';
import classNames from 'classnames';
import SelectBox from '../components/base/SelectBox';
import MarcSprctorLinksGroup from '../components/parts/MarcSprctorLinksGroup';
import { Link, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getApplicationByIdFetch, getJobByIdFetch } from '../redux/thunks/jobsThunks';
import PageLoader from '../components/PageLoader';
import { format } from 'date-fns';
import { crumbsConfig } from './SBM01_Form/config';
import { getSubscriberRole } from '../redux/slices/applicationSlice';
import { TabsSBM01 } from './SBM01_Form/base/TabsSBM01';


const SBM01_10_14_2 = () => {
	const dispatch = useDispatch();
	const { jobId, applicationId } = useParams();
	const { job, isLoading, application } = useSelector(state => state.jobs)

	const subscriberRole = useSelector(getSubscriberRole);

	useEffect(() => {
		if (!jobId || !applicationId) return;
		dispatch(getJobByIdFetch(jobId))
		dispatch(getApplicationByIdFetch({
			applicationId,
			jobId
		}))
	}, [jobId, applicationId, dispatch])
	const crumbs = [crumbsConfig.sub, crumbsConfig.jobs, ['Applicants', `/SBM01_2/${jobId}?tab=applicants`], ["Candidates", `/SBM01_2/${jobId}?tab=candidates`], [application?.fullName, ''], "Report Card"]
	
	const reportCardData = [
		{
			title: "Clarification",
			percentage: "96%",
			status: "Completed",
			completed: true,
			borderColor: "border-sky-900",
			linkTo: `/SBM01_10_18/${jobId}/${applicationId}`
		},
		{
			title: "1st Interview",
			status: "A Score",
			progress: '56%',
			borderColor: "border-gray-200",
			linkTo: `/SBM01_10_1/${jobId}/${applicationId}`
		},
		{
			title: "Post Interview Survey",
			status: "A Score",
			progress: '20',
			borderColor: "border-gray-200",
			linkTo: `/SBM01_10_2/${jobId}/${applicationId}`
		},
		{
			title: "2nd Interview",
			status: "On-going",
			borderColor: "border-gray-200",
			linkTo: `/SBM01_10_3/${jobId}/${applicationId}`
		},
		{
			title: "Document Review",
			status: "Not Started",
			borderColor: "border-gray-200",
			linkTo: `/SBM01_10_4/${jobId}/${applicationId}`
		},
		{
			title: "Rating of the Job",
			status: "Not Started",
			borderColor: "border-gray-200",
			linkTo: `/SBM01_10_16/${jobId}/${applicationId}`
		},
		{
			title: "AI Evaluation",
			status: "Not Started",
			borderColor: "border-gray-200",
			linkTo: `/SBM01_10_5/${jobId}/${applicationId}`
		},
		{
			title: "Final Hiring Decisions",
			status: "Not Started",
			borderColor: "border-gray-200",
			linkTo: `/SBM01_10_5_5/${jobId}/${applicationId}`
		},
		{
			title: "Training Insights",
			status: "Not Started",
			borderColor: "border-gray-200",
			linkTo: `/SBM01_10_6/${jobId}/${applicationId}`
		},
		{
			title: "Offer Meeting",
			status: "Not Started",
			borderColor: "border-gray-200",
			linkTo: `/SBM01_10_7/${jobId}/${applicationId}`
		},
		{
			title: "Training Milestones",
			status: "Not Started",
			borderColor: "border-gray-200",
			linkTo: `/SBM01_10_8/${jobId}/${applicationId}`
		},
	];
	return (
		<>

			{job && application && !isLoading ?
				<>
					<BreadCrumbWhite crumbs={crumbs} />
					<div className='max-w-[1432px] flex-auto px-4 w-full pt-10 pb-20 lg:pt-[60px] lg:pb-[90px] mx-auto'>
						<p className="text-lg font-semibold text-sky-900">{job.jobTitle} / Candidates</p>
						<h2 className="mt-1.5 text-4xl font-bold leading-10 text-zinc-950">
							{application.fullName}
						</h2>
						{
							subscriberRole !== "interviewer" ?
								// <MarcSprctorLinksGroup activeLink={'report-card'} />
								<TabsSBM01 activeLink={'report-card'} />   
								:
								null
						}
						<h2 className='my-9 lg:my-[60px] text-[28px] lg:text-[30px] font-bold text-[#333333]'>Report Card</h2>
						<div className='flex items-center gap-3 lg:gap-[18px] max-lg:mb-4'>
							<div className='aspect-square w-6 lg:w-[50px] flex items-center justify-center'>
								<Checkbox />
							</div>
							<p className='lg:text-2xl text-lg font-bold text-[#37B34A]'>{application.fullName}</p>
							<p className='text-base lg:text-lg font-normal text-[#666666]'>Applied Date {format(application.createdAt, 'MM/dd/yyyy')}</p>
						</div>
						<div className='flex items-center overflow-auto gap-[2px] border-b border-solid border-[#DEDED9]'>
							{(subscriberRole !== "interviewer" ? reportCardData : reportCardData.slice(0, 4)).map((item, index) => <div
								className='flex-1 flex flex-col items-center min-w-[150px]'
								key={index}
							>
								<div className={classNames('w-full h-[90px] flex items-center flex-col gap-[6px] justify-center border-b-[5px] border-solid', item.completed ? 'border-[#003578]' : 'border-[#EBE9EA]')}>
									<Link to={item.linkTo} className='text-base font-bold text-[#37B34A] max-w-[150px] line-clamp-2 text-center'>{item.title}</Link>
									<p className='text-base font-normal text-[#777777]'>{item.percentage || 'Send'}</p>
								</div>
								<div className='h-[90px] flex items-center justify-center'>
									<p className='text-base font-normal text-[#777777]'>{item.status} {item.progress && <span className='text-[#37B34A]'>({item.progress})</span>}</p>
								</div>
							</div>)}
						</div>

					</div>
				</>
				: isLoading ? <PageLoader /> : <h2 className="text-4xl font-bold text-center my-12">Not found</h2>}
			<Footer hideCopyright={true} />
		</>
	)
}

export default SBM01_10_14_2