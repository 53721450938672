import { useEffect } from 'react';
import { useNavigate, useOutletContext } from 'react-router-dom';
import { RangeQuestion } from "../../AP_01_Form/parts/RangeQuestion";
import { TextQuestion } from "../../AP_01_Form/parts/TextQuestion";
import { VariantsQuestion } from "../../AP_01_Form/parts/VariantsQuestion";
import { YesNoQuestion } from "../../AP_01_Form/parts/YesNoQuestion";
import { NextStepSectionSBM01 } from '../base/NextStepSectionSBM01';
import CommunicationTable from '../parts/CommunicationTable';

const Clarifications = () => {
	const navigate = useNavigate()
	const { job, application, jobId, applicationId, setActiveStep } = useOutletContext()
	useEffect(() => {
		setActiveStep(1)
	}, [])

	const questionsData = job && application ? job.applicationForm.critical_requirements_of_the_job_questions.map((item, index) => ({ ...item, answerValue: application.applicationForm.critical_requirements_of_the_job_answers[index] })).filter(item => item.questionCode.includes('CL')) : [];
	const QuestionTypes = {
		'yes-no': YesNoQuestion,
		'text': TextQuestion,
		'range': RangeQuestion,
		'variants': VariantsQuestion
	}

	const handleNext = () => {
		navigate(`/SBM01_10_1/${jobId}/${applicationId}`)
	}

	return (
		<div className='flex-auto'>
			<div className='flex flex-col gap-[40px] md:gap-[60px] max-w-[1432px] px-4 w-full pt-14 pb-24 mx-auto'>
				<div className="flex flex-col gap-[36px]">
					<div className="flex flex-col gap-[32px] self-stretch max-w-[720px]">
						{questionsData.map((item, index) => {
							const Question = QuestionTypes[item.answerType];
							if (!Question) return null
							return <Question
								key={item.id}
								error={null}
								config={item}
								number={index + 1}
								value={item.answerValue || null}
								className="mt-[0px]"
								labelClassName="!text-[16px] !leading-[20px]"
							/>
						})}
					</div>
				</div>
				<div className="flex flex-col gap-7">
					<h1 className="self-stretch w-full text-3xl font-bold leading-8 max-md:max-w-full">Clarification</h1>
					<div className="self-stretch w-full border border-solid bg-neutral-200 border-neutral-200 min-h-[1px] max-md:max-w-full"></div>
					<p className="text-2xl font-bold">Communication History</p>
					<CommunicationTable />
				</div>
			</div>
			<NextStepSectionSBM01 next={handleNext} />
		</div>
	)
}

export default Clarifications