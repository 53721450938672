import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useOutletContext } from 'react-router-dom';
import SelectField from "../../../components/base/SelectField";
import { getSubscriberRole } from '../../../redux/slices/applicationSlice';
import { RangeQuestion } from "../../AP_01_Form/parts/RangeQuestion";
import { TextQuestion } from "../../AP_01_Form/parts/TextQuestion";
import { VariantsQuestion } from "../../AP_01_Form/parts/VariantsQuestion";
import { YesNoQuestion } from "../../AP_01_Form/parts/YesNoQuestion";
import { NextStepSectionSBM01 } from '../base/NextStepSectionSBM01';

const InterviewSection = ({ questions }) => {
	const [recruiter, setRecuter] = useState()

	const recruiters = [
		{
			value: "recruiter_1",
			label: "Recruiter 1"
		},
		{
			value: "recruiter_2",
			label: "Recruiter 2"
		},
	]
	const handleSelect = (value) => {
		setRecuter(value)
	}

	return (
		<div className="flex flex-col items-stretch">
			<div className="self-stretch w-full text-3xl font-bold leading-8 text-zinc-800 max-md:max-w-full">
				2<sup>st</sup> Interview
			</div>
			<div className="self-stretch mt-9 w-full border border-solid bg-neutral-200 border-neutral-200 min-h-[1px] max-md:max-w-full" />

			<div className="flex max-lg:flex-col -mb-4 justify-start gap-2 lg:gap-24 self-stretch mt-9 w-full">
				<div className="my-auto lg:text-lg font-semibold text-zinc-800">
					List of recruiter names
				</div>
				<SelectField
					inputBg="bg-white !rounded-[0px] border border-solid border-zinc-300 max-w-[815px] w-full"
					name="department"
					id="department"
					value={recruiter}
					onChange={handleSelect}
					options={recruiters}
				/>
			</div>
			<div className="mt-[32px]">
				{questions}
			</div>
		</div>
	);
};

const TotalScore = ({ title, score }) => (
	<div className="flex gap-5 md:mt-6 max-w-full text-lg whitespace-nowrap w-[400px] text-center">
		<div className="grow justify-center items-center px-16 py-4 font-semibold bg-neutral-100 w-fit max-md:px-5">
			{title}
		</div>
		<div
			className="flex gap-2 justify-center px-5 py-4 min-w-[96px] bg-white border border-solid border-zinc-300 max-md:px-5"
		>
			<div>{score}%</div>
		</div>
	</div>
);

const ScoreCard = ({ category, score }) => (
	<div className="flex gap-5 justify-between max-w-[400px] w-full">
		<div className="my-auto font-bold">{category}</div>
		<div className="flex gap-2 justify-center px-5 py-4 min-w-[96px] bg-white border border-solid border-zinc-300 max-md:px-5">{score}</div>
	</div>
);

const EvaluationResults = () => {
	const scores = [
		{ category: "Stability", score: "90%" },
		{ category: "Fitness for the Job", score: "90%" },
		{ category: "Physicality", score: "90%" },
		{ category: "Open Mindedness", score: "90%" },
		{ category: "Quality", score: "90%" },
		{ category: "Coachability", score: "90%" }
	];

	return (
		<main className="flex flex-col items-start text-lg">
			<section className="flex gap-5 max-w-full whitespace-nowrap text-zinc-800 w-[400px]">
				<TotalScore title={"Total Score"} score={90} />
			</section>
			<div className='flex justify-between max-w-[900px]'>
				<section className="flex flex-wrap gap-y-6 max-w-[900px] mt-7 max-md:mt-[16px] w-full text-zinc-800 max-md:gap-y-[16px]">
					{scores.map((score, index) => (
						<div
							className={`w-full lg:w-1/2 flex ${index % 2 !== 0 ? "lg:justify-end" : ""}`}
							key={index}
						>
							<ScoreCard category={score.category} score={score.score} />
						</div>
					))}
				</section>
			</div>
			<footer className="flex gap-5 justify-between self-stretch mt-12 w-full text-center max-md:flex-wrap max-md:mt-10 max-md:max-w-full">
				<button className="max-md:flex-1 px-2 py-3 md:py-5 min-w-[150px] font-semibold text-green-500 underline hover:opacity-70" tabIndex="0">
					See More
				</button>
				<button className="justify-center max-md:flex-1 min-w-[150px] px-2 py-3 md:py-5 font-bold text-white bg-sky-900 rounded-md max-md:px-5 hover:opacity-70" tabIndex="0">
					Save
				</button>
			</footer>
		</main>
	);
};

const SecondInterview = () => {
	const subscriberRole = useSelector(getSubscriberRole)
	const navigate = useNavigate()
	const { job, application, jobId, applicationId, setActiveStep } = useOutletContext()
	useEffect(() => {
		setActiveStep(4)
	}, [])

	const questionsData = job && application ? job.applicationForm.critical_requirements_of_the_job_questions.map((item, index) => ({ ...item, answerValue: application.applicationForm.critical_requirements_of_the_job_answers[index] })).filter(item => item.questionCode.includes('IP')) : [];
	const QuestionTypes = {
		'yes-no': YesNoQuestion,
		'text': TextQuestion,
		'range': RangeQuestion,
		'variants': VariantsQuestion
	}

	const handlePrev = () => {
		navigate(`/SBM01_10_2/${jobId}/${applicationId}`)
	}
	const handleNext = () => {
		navigate(`/SBM01_10_4/${jobId}/${applicationId}`)
	}

	return (
		<div className='flex-auto'>
			<div className='flex flex-col gap-11 max-w-[1432px] px-4 w-full pt-14 pb-24 mx-auto max-md:pt-[40px] max-md:pb-[80px] max-md:gap-[36px]'>
				<InterviewSection
					questions={<div className="flex flex-col gap-[32px] self-stretch max-w-[720px]">
						{questionsData.map((item, index) => {
							const Question = QuestionTypes[item.answerType];
							if (!Question) return null
							return <Question
								key={item.id}
								error={null}
								config={item}
								number={index + 1}
								value={item.answerValue || null}
								className="mt-[0px]"
								labelClassName="!text-[16px] !leading-[20px]"
							/>
						})}
					</div>}
				/>
				<EvaluationResults />
			</div>
			<NextStepSectionSBM01 prev={handlePrev} next={subscriberRole !== "interviewer" ? handleNext : null} />
		</div>
	)
}

export default SecondInterview