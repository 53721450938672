import { useCallback, useState, createRef, useEffect } from 'react';
import { useDropzone } from 'react-dropzone';
import LoadIcon from "../../assets/img/load-icon.svg";
import ReplaceIcon from "../../assets/img/replace-icon.svg";
import DeleteIcon from "../../assets/img/delete-icon.svg";
import { Link } from 'react-router-dom';

const ButtonWithLabel = ({ onClick, label, altText }) => (
    <button
        className="flex gap-2 items-center justify-center px-8 py-2.5 rounded-md bg-neutral-200 hover:bg-neutral-300 focus:outline-none focus:ring-2 focus:ring-neutral-400 focus:ring-offset-2"
        onClick={onClick}
    >
        <img loading="lazy" src={label.src} alt={altText} className="w-6 h-6" />
        <span>{label.text}</span>
    </button>
);

const FileInputField = ({ fileProp, boxClassName = "", loadBtnShow = false, handleChange = () => { }, acceptProp, hideActions }) => {
    const inputRef = createRef(null);

    const [file, setFile] = useState(fileProp || null);

    useEffect(() => {
        if (fileProp) {
            setFile(Object.assign(fileProp, {
                preview: URL.createObjectURL(fileProp)
            }));
        }
    }, [fileProp]);

    useEffect(() => {
        handleChange && handleChange(file)
    }, [file]);


    const onDrop = useCallback(acceptedFiles => {
        const file = acceptedFiles[0];
        setFile(Object.assign(file, {
            preview: URL.createObjectURL(file)
        }));

    }, []);

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop,
        accept: acceptProp ? acceptProp : {
            'image/*': ['.jpeg', '.jpg', '.png'],
        },
        multiple: false,
    });

    return (
        <div className={`box-border flex flex-col items-start justify-center ${boxClassName ? boxClassName : ''}`} ref={inputRef}>
            <div {...getRootProps()} className={`flex flex-col items-center justify-center rounded-[6px] p-[8px] md:p-[20px] cursor-pointer hover:bg-neutral-50 border border-solid border-neutral-300 mb-[16px] w-full lg:max-w-[332px] ${file ? '!h-[1px] !opacity-[0] !p-[0] !mb-[0] !overflow-hidden' : ''}`}>
                <input {...getInputProps()} />
                {isDragActive ? (
                    <div className="flex items-center gap-[20px] min-h-[44px]">
                        <p className="text-neutral-700">Drop the images here ...</p>
                    </div>
                ) : (
                    <div className="flex items-center w-full">
                        <button
                            type='button'
                            className="flex gap-2 flex-1 items-center justify-center px-2 py-2.5 rounded-md bg-neutral-200"
                        >
                            Choose File
                        </button>
                        <p className="text-neutral-700 flex-1 text-center px-2">

                            {!file ? "No file choses" : "Drag 'n' drop some files here, or click to select files"}
                        </p>
                    </div>
                )}
            </div>
            {file && <>
                <aside className="flex flex-col gap-4 mb-[16px]">
                    {file && (
                        <div className="flex gap-5 max-md:flex-col max-md:gap-0 border border-solid border-stone-300 p-[20px]">
                            {file.type === 'application/pdf' ? <span className="flex w-[26px] aspect-square">
                                <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M22.5641 4.73015L20.7711 3.5114C20.275 3.17546 19.5953 3.30437 19.2633 3.79656L10.4742 16.7575L6.43518 12.7184C6.01331 12.2966 5.32581 12.2966 4.90393 12.7184L3.36877 14.2536C2.9469 14.6755 2.9469 15.363 3.36877 15.7887L9.57971 21.9997C9.92737 22.3473 10.4742 22.613 10.9664 22.613C11.4586 22.613 11.9547 22.3044 12.275 21.8395L22.8531 6.23406C23.1891 5.74187 23.0602 5.06609 22.5641 4.73015Z" fill="#06D6A0" />
                                </svg>
                            </span> :
                                <div className="flex flex-col max-md:ml-0 max-md:w-full">
                                    <div className="mx-auto bg-neutral-200 max-h-[92px] max-w-[92px] max-md:mt-10">
                                        <img src={file.preview} alt="Preview" className="w-full max-h-[100%] object-cover rounded-md" />
                                    </div>
                                </div>
                            }
                            <div className="flex flex-col ml-5 max-md:ml-0 max-md:w-full">
                                <div className="self-stretch my-auto text-base text-zinc-800 max-md:mt-10">
                                    {file.type === 'application/pdf' ?
                                        <Link className='underline underline-offset-1 hover:no-underline' target='_blank' to={file.preview}>{file.name}</Link>
                                        : file.name}
                                </div>
                            </div>
                        </div>
                    )}
                </aside>
                {!hideActions ?
                    <div className="flex gap-4">
                        {loadBtnShow ? <ButtonWithLabel
                            onClick={() => { }}
                            label={{ src: LoadIcon, text: 'Load' }}
                            altText="Load file button"
                        /> : null}
                        <ButtonWithLabel
                            onClick={() => {
                                inputRef?.current.querySelector('input').click();
                            }}
                            label={{ src: ReplaceIcon, text: 'Replace' }}
                            altText="Replace file button"
                        />
                        <ButtonWithLabel
                            onClick={() => {
                                setFile(null);
                            }}
                            label={{ src: DeleteIcon, text: 'Delete' }}
                            altText="Delete file button"
                        />
                    </div>
                    : null
                }
            </>}
        </div>
    )
};

export default FileInputField;