import classNames from "classnames";
import { forwardRef } from "react";

const InputField = forwardRef(({ label, labelClassName, max, type = "text", name, id, value, onChange, error, inputBg = "bg-[#F0F3F8]", parentClassName, placeholder = "", afterLabel = null, className, disabled = false, disabledClassName = "!bg-zinc-100", ...props }, ref) => (
	<div className={`flex flex-col gap-[16px] max-md:gap-[12px] flex-1 mb-[18px] ${parentClassName ? parentClassName : ''}`}>
		{ label ? <label htmlFor={id} className={labelClassName ? labelClassName : "text-[16px] md:text-[18px] font-bold text-zinc-800 leading-[18px]"}>{label}</label> : null }
		{afterLabel}
		<input
			type={type}
			id={id}
			name={name}
			className={classNames(`rounded-md px-[18px] text-[18px] focus:outline-none focus:ring-2 focus:ring-green-500 ${disabled ? disabledClassName : ""} ${error ? 'border-red-500 bg-red-100' : inputBg} h-[54px] w-full`, className)}
			value={value}
			onChange={onChange}
			placeholder={placeholder}
			disabled={disabled}
			maxLength={max}
			ref={ref}
      		{...props}
		/>
		{error && error.length && <p className="text-red-500 text-sm mt-1">{error}</p>}
	</div>
));

export default InputField;