const base = 'api/communications';

export const communications = (instance) => ({
    async getAllComunications({ applicationId, query }) {
        const { data } = await instance.get(`${base}/${applicationId}?${query}`);
        return data;
    },
    async sendMessageToApplicant({ applicationId, body }) {
        const { data } = await instance.post(`${base}/${applicationId}/send-message`, body);
        return data;
    },
});

